<template>
  <div>
    <v-row dense>
      <v-col v-if="!inWorkspace">
        <v-text-field
          dense
          autofocus
          persistent-hint
          label="Entity identifier"
          :hint="
            createMode
              ? 'Try to set a descriptive entity identifier. It may contain only lowercase letters, ' +
                'numbers and underscore should be used to separate words.'
              : ''
          "
          :model-value="modelValue.id"
          :rules="createMode ? [validateId] : []"
          :readonly="!createMode"
          @update:model-value="updateFormData({ ...modelValue, id: $event })"
        />
      </v-col>
      <v-col v-if="inWorkspace">
        <v-text-field v-model="name" dense autofocus :readonly="!createMode" label="Name" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-textarea
          :model-value="modelValue.description"
          spellcheck="true"
          lang="en"
          :readonly="readonly"
          label="Description"
          rows="1"
          auto-grow
          @update:model-value="updateFormData({ ...modelValue, description: $event })"
        />
      </v-col>
    </v-row>

    <v-row v-if="!inWorkspace" dense>
      <v-col :cols="createMode ? 6 : 12">
        <v-autocomplete
          v-model="modelValue.project"
          clearable
          auto-select-first
          label="Project *"
          item-title="name"
          item-value="id"
          :items="projects"
          :return-object="false"
          :rules="createMode ? [requiredField] : []"
        />
      </v-col>

      <v-col :cols="createMode ? 6 : 12">
        <v-autocomplete
          label="Category *"
          clearable
          auto-select-first
          :rules="createMode ? [requiredField] : []"
          :readonly="readonly"
          :model-value="modelValue.category"
          :items="categoryItems"
          @update:model-value="updateCategory($event)"
        />
      </v-col>

      <v-col v-if="!createMode && inMessaging" cols="12">
        <v-select
          v-model="modelValue.spotlight"
          label="Spotlight"
          item-title="title"
          item-value="id"
          :readonly="readonly"
          :items="spotlightItems"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model:search="tagInput"
          chips
          dense
          multiple
          deletable-chips
          auto-select-first
          label="Related feature(s)"
          item-title="text"
          item-value="value"
          :model-value="modelValue.tags"
          :readonly="readonly"
          :return-object="false"
          :items="insightsFeatureTags"
          @update:model-value="addTag($event)"
        >
          <template #no-data>
            <v-list-item>
              <v-list-item-title>
                <div>
                  No features matching
                  <strong>"{{ tagInput }}"</strong>
                  .
                  <br />
                  Click&nbsp;
                  <strong>"Add feature"</strong>
                  &nbsp;to create a new one.
                </div>
                <div class="d-flex mt-4">
                  <v-spacer />

                  <v-btn text="Add feature" color="primary" @click="addNewTag()" />
                </div>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import slug from 'slug'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { doc, getFirestore, setDoc } from 'firebase/firestore'

  import { insightGroups } from '#views/insights/constants'

  import { AppStore, FormulasStore, InsightsStore, ProjectsStore } from '#stores'

  import { Category, Insight, Template } from '#types'

  import { isIdentifierValid } from '#utilities'

  @Component
  class InsightMetaFields extends Vue {
    @Prop() public store!: string

    @Prop() public readonly!: boolean

    @Prop() public modelValue!: Insight

    @Prop() public categoryItems!: Category[]

    @Prop() public template!: Template | null

    @Prop({ default: false }) public createMode!: boolean

    public name = ''
    public tagInput = ''

    public readonly insightGroups = insightGroups

    protected readonly appStore = new AppStore()

    protected readonly formulasStore = new FormulasStore()
    protected readonly insightsStore = new InsightsStore()
    protected readonly projectsStore = new ProjectsStore()

    public get project() {
      return this.projectsStore.project
    }

    public get projects() {
      return this.projectsStore.projects.filter((p) => p.sections.content || p.id === this.modelValue.project)
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get spotlightItems() {
      return this.formulasStore.evals
    }

    public get insightsFeatureTags() {
      return this.insightsStore.featureTags
    }

    @Watch('name')
    protected nameChanged(newVal: any) {
      this.updateId(newVal)
    }

    @Watch('value')
    protected insightChanged() {
      this.updateId(this.modelValue.id)
    }

    @Watch('template')
    protected templateChanged() {
      // If template is set, set category to template category
      if (this.template) {
        this.modelValue.category = this.template?.category ?? ''
      }
    }

    @Emit('update:modelValue')
    public updateFormData(input: unknown) {
      return input
    }

    @Emit('update:template')
    public updateTemplate(template: Template | null) {
      return template
    }

    @Emit('categoryChanged')
    public categoryChanged() {
      return true
    }

    public mounted() {
      this.updateId(this.modelValue.id)
    }

    private updateId(id: string) {
      if (this.createMode) {
        id = slug(id, '_')
      }

      this.modelValue.id = id
      this.name = id
    }

    public updateCategory(category: string) {
      this.updateFormData({ ...this.modelValue, category })

      // when category changes, reset template
      if (this.template) {
        this.updateTemplate(null)
      }

      // Changing category has side-effects to form validation.
      this.categoryChanged()
    }

    public addTag(tags: string[]) {
      this.updateFormData({ ...this.modelValue, tags })
      this.tagInput = ''
    }

    public async addNewTag() {
      const key = this.tagInput.toLowerCase().replace(/ /g, '_')

      if (key && this.modelValue) {
        await setDoc(doc(getFirestore(), `tags/${key}`), {
          name: this.tagInput[0].toUpperCase() + this.tagInput.slice(1),
        })

        this.updateFormData({ ...this.modelValue, tags: [...this.modelValue.tags, key] })
      }

      this.tagInput = ''
    }

    public validateId(value: string) {
      // This allows workspace to have COPY:N: in the beginning of the id, so it doesn't break when updating description etc.
      if (this.inWorkspace) {
        value = value.replace(/:/g, '_')
      }

      return (
        isIdentifierValid(value) ||
        'Invalid characters or too short id, use only lowercase letters, number and underscore'
      )
    }

    public requiredField(value: string) {
      return !!value || 'This field is required'
    }
  }

  export default toNative(InsightMetaFields)
</script>
