<template>
  <v-dialog v-model="isOpen" width="800px" scrollable>
    <v-card v-if="isLettingUserSelect">
      <v-card-title class="headline">Download content</v-card-title>

      <v-card-text>
        <div class="my-6">
          <p>Please select what type of content and in what state you want to download.</p>
        </div>

        <v-row>
          <v-col class="px-8" cols="4">
            <div class="overline">Content type</div>

            <v-radio-group v-model="downloadType">
              <v-radio label="Insight Messages" :value="'insights'" />
              <v-radio label="Tip Theme Texts" :value="'tipthemes'" />
              <v-radio label="Slide Show Slides" :value="'slideshows'" />
              <v-radio label="Explore Contents" :value="'contents'" />
              <!-- <v-radio label="My Workspace (All)" :value="'workspace'" /> -->
            </v-radio-group>
          </v-col>

          <v-col class="px-8" cols="8">
            <div class="overline">Content states</div>

            <v-radio-group v-model="downloadStates" :disabled="downloadType === 'workspace'">
              <v-radio label="Only Production" :value="'production'" />
              <v-radio label="Experimental and Production" :value="'experimental'" />
              <v-radio label="Drafts, Experimental and Production" :value="'everything'" />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn color="primary" text="Download" @click="downloadInsights()" />
      </v-card-actions>
    </v-card>

    <v-card v-if="isDownloadingContent" persistent :loading="isDownloadingContent">
      <v-card-title class="headline">Downloading content</v-card-title>

      <v-card-text>
        <div class="mt-6">Please wait. This may take a while.</div>
      </v-card-text>
    </v-card>

    <v-card v-if="contentExport.length || translationsExport.length" persistent scrollable>
      <v-card-title class="headline">Export errors</v-card-title>

      <v-card-text>
        <v-tabs v-model="tabs" fixed-tabs>
          <v-tab href="#error-tab-2">Condition errors ({{ getErrorCount(contentExport) }})</v-tab>
          <v-tab href="#error-tab-1">Translation errors ({{ getErrorCount(translationsExport) }})</v-tab>
        </v-tabs>

        <v-window v-model="tabs">
          <v-window-item key="2" value="error-tab-2">
            <div v-if="!getErrorCount(contentExport)" class="px-4 py-8">No condition errors, all good!</div>

            <div v-else>
              <div v-for="insightExport in contentExport" :key="insightExport.content.id">
                <div v-if="insightExport.errors.length" class="headline mt-4">
                  {{ insightExport.content.id }} ({{ insightExport.errors.length }})
                </div>
                <div v-for="(error, index) in insightExport.errors" :key="index" class="pt-2">
                  {{ error }}
                </div>
              </div>
            </div>
          </v-window-item>

          <v-window-item key="1" value="error-tab-1">
            <div v-if="!getErrorCount(translationsExport)" class="px-4 py-8">No translation errors, all good!</div>

            <div v-else>
              <div v-for="translationExport in translationsExport" :key="translationExport.language">
                <div v-if="translationExport.errors.length" class="headline mt-4">
                  {{ translationExport.language }} ({{ translationExport.errors.length }})
                </div>

                <v-divider v-if="translationExport.errors.length" class="mb-4" />

                <div v-for="(error, index) in translationExport.errors" :key="index" class="pt-2">
                  {{ error }}
                </div>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn text="Close" @click="closeModal()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, ContentsStore, InsightsStore } from '#stores'

  import { ExportContent, ExportTranslations } from '#types'

  @Component({})
  class DownloadDialog extends Vue {
    public tabs = null

    public isOpen = false
    public isLettingUserSelect = false
    public isDownloadingContent = false

    public downloadType = 'insights'
    public downloadStates = 'production'

    public contentExport: ExportContent[] = []
    public translationsExport: ExportTranslations[] = []

    private appStore = new AppStore()
    private contentsStore = new ContentsStore()
    private insightsStore = new InsightsStore()

    public open() {
      this.isOpen = true
      this.contentExport = []
      this.translationsExport = []
      this.isLettingUserSelect = true
    }

    public closeModal() {
      this.isOpen = false
      this.contentExport = []
      this.translationsExport = []
    }

    public getErrorCount(result: any) {
      return result.reduce((count: number, item: any) => {
        return count + item.errors.length
      }, 0)
    }

    public async downloadInsights() {
      let states: string[] | undefined = undefined

      this.isLettingUserSelect = false
      this.isDownloadingContent = true

      let collection = this.downloadType

      if (this.downloadType === 'workspace') {
        collection = `users/${this.appStore.user?.uid}/insights`
      } else {
        states = ['production', 'archived']

        if (this.downloadStates === 'everything') {
          states.push('experimental')
          states.push('draft')
        }

        if (this.downloadStates === 'experimental') {
          states.push('experimental')
        }
      }

      if (this.downloadType === 'contents') {
        const { contents, translations } = await this.contentsStore.downloadContents({
          contentStates: states,
        })

        this.contentExport = contents
        this.translationsExport = translations
      } else {
        const { insights, translations } = await this.insightsStore.downloadInsights({
          insightStates: states,
          collectionPath: collection,
        })

        this.contentExport = insights
        this.translationsExport = translations
      }

      this.isDownloadingContent = false

      if (!this.contentExport.length && !this.translationsExport.length) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    }
  }

  export default toNative(DownloadDialog)
</script>
