<template>
  <div>
    <v-app-bar>
      <v-app-bar-title>
        <v-menu v-model="viewMenu" offset="18">
          <template #activator="{ props }">
            <span v-bind="props" style="cursor: pointer">
              Insights / {{ $route.meta && $route.meta.title }} ({{ searchResults.length }})
            </span>

            <v-icon>mdi-triangle-small-down</v-icon>
          </template>

          <v-list>
            <v-list-item
              to="/messaging"
              title="Messaging"
              prepend-icon="mdi-text-box-outline"
              :style="inMessaging && 'pointer-events: none'"
            />
            <v-list-item
              to="/tipthemes"
              title="Tip Themes"
              prepend-icon="mdi-information-slab-box-outline"
              :style="inTipthemes && 'pointer-events: none'"
            />
            <v-list-item
              to="/slideshows"
              title="Slide Shows"
              prepend-icon="mdi-image-multiple-outline"
              :style="inSlideshows && 'pointer-events: none'"
            />
          </v-list>
        </v-menu>
      </v-app-bar-title>

      <v-spacer />

      <v-text-field
        v-model="routeParams.search"
        clearable
        class="mr-2"
        style="max-width: 500px"
        prepend-icon="mdi-magnify"
        placeholder="Type to filter insights..."
      />

      <v-tooltip location="bottom" text="Find and replace">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="mdi-find-replace" @click="findReplaceDialog = true" />
        </template>
      </v-tooltip>

      <v-menu>
        <template #activator="{ props: menu }">
          <v-tooltip location="bottom">
            <template #activator="{ props: tooltip }">
              <v-btn v-bind="mergeProps(menu, tooltip)" color="primary" icon="mdi-export" />
            </template>
            <span>Export data</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item
            title="Copy IDs to clipboard"
            append-icon="mdi-content-copy"
            @click="useClipboard.copy(searchResultIds.join('\n'))"
          />

          <v-list-item
            title="Export search results"
            append-icon="mdi-download"
            :disabled="!searchResultIds.length"
            @click="exportInsightsDialog = true"
          />
        </v-list>
      </v-menu>

      <v-menu
        v-model="filterMenu"
        offset="8"
        min-width="230"
        max-height="500"
        :close-on-content-click="false"
        @click.stop
      >
        <template #activator="{ props: menu }">
          <v-tooltip location="bottom" text="Filter options">
            <template #activator="{ props: tooltip }">
              <v-btn
                icon="mdi-filter-variant-plus"
                v-bind="mergeProps(menu, tooltip)"
                :color="menu.value ? 'primary' : ''"
              />
            </template>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-subheader>Select additional filters</v-list-subheader>

          <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-list-item v-bind="props" title="Feature tags" prepend-icon="mdi-menu-left" />
            </template>

            <v-list>
              <v-list-item
                v-for="item in appFeatureTags"
                :key="item.value"
                :title="item.text"
                :disabled="routeParams.tagFilters.includes(item.value)"
                @click="routeParams.tagFilters = [...routeParams.tagFilters, item.value]"
              />
            </v-list>
          </v-menu>

          <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-list-item v-bind="props" title="Insight category" prepend-icon="mdi-menu-left" />
            </template>

            <v-list>
              <v-list-item
                v-for="item in categoryItems"
                :key="item.value"
                :title="item.title"
                :disabled="routeParams.categoryFilters.includes(item.value)"
                @click="routeParams.categoryFilters = [...routeParams.categoryFilters, item.value]"
              />
            </v-list>
          </v-menu>

          <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-list-item v-bind="props" title="Publishing state" prepend-icon="mdi-menu-left" />
            </template>

            <v-list>
              <v-list-item
                v-for="item in stateItems"
                :key="item.value"
                :title="item.title"
                :disabled="routeParams.stateFilters.includes(item.value)"
                @click="routeParams.stateFilters = [...routeParams.stateFilters, item.value]"
              />
            </v-list>
          </v-menu>

          <v-divider />

          <v-list-item
            prepend-icon="mdi-close-circle-outline"
            title="Clear all filters"
            :disabled="
              !routeParams.tagFilters.length && !routeParams.stateFilters.length && !routeParams.categoryFilters.length
            "
            @click="
              ((routeParams.tagFilters = []),
              (routeParams.stateFilters = []),
              (routeParams.categoryFilters = []),
              (filterMenu = false))
            "
          />
        </v-list>
      </v-menu>

      <v-menu offset="8" @click.stop>
        <template #activator="{ props: menu }">
          <v-tooltip location="bottom" text="Sort order">
            <template #activator="{ props: tooltip }">
              <v-btn
                icon="mdi-sort-numeric-descending"
                v-bind="mergeProps(menu, tooltip)"
                :color="menu.value ? 'primary' : ''"
              />
            </template>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-subheader>Sort order for the insights</v-list-subheader>

          <v-list-item
            title="Last edited (desc)"
            prepend-icon="mdi-clipboard-text-clock-outline"
            :disabled="routeParams.sortOrder === ''"
            @click="routeParams.sortOrder = ''"
          />

          <v-list-item
            title="Alphabetical (desc)"
            prepend-icon="mdi-sort-alphabetical-descending"
            :disabled="routeParams.sortOrder === 'alphabetical'"
            @click="routeParams.sortOrder = 'alphabetical'"
          />

          <v-list-item
            title="Generated count (desc)"
            prepend-icon="mdi-sort-alphabetical-descending"
            :disabled="routeParams.sortOrder === 'generated'"
            @click="routeParams.sortOrder = 'generated'"
          />

          <v-list-item
            title="Generated vs. viewed (desc)"
            prepend-icon="mdi-sort-numeric-descending"
            :disabled="routeParams.sortOrder === 'viewed'"
            @click="routeParams.sortOrder = 'viewed'"
          />

          <v-list-item
            title="Generated vs. clicked (desc)"
            prepend-icon="mdi-sort-numeric-descending"
            :disabled="routeParams.sortOrder === 'clicked'"
            @click="routeParams.sortOrder = 'clicked'"
          />
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-progress-linear v-if="searching" absolute indeterminate />

    <v-container>
      <v-row>
        <v-col v-if="inMessaging" cols="12" md="9">
          <div variant="text" class="text-h5 font-weight-light">Insights translate the users data into text</div>
          <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
            <template v-if="isInsightsEditor">
              Your insights editor rights allow you to view and edit all insights
            </template>
            <template v-else>You can only view insights, apply for editor rights in the IT portal</template>
          </div>
        </v-col>

        <v-col v-if="inTipthemes" cols="12" md="9">
          <div variant="text" class="text-h5 font-weight-light">Tip themes wraps health tips under common theme</div>
          <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
            <template v-if="isInsightsEditor">
              Your insights editor rights allow you to view and edit all tip themes
            </template>
            <template v-else>You can only view tip themes, apply for editor rights in the IT portal</template>
          </div>
        </v-col>

        <v-col v-if="inSlideshows" cols="12" md="9">
          <div variant="text" class="text-h5 font-weight-light">Slide Shows combine images and text into slides</div>
          <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
            <template v-if="isInsightsEditor">
              Your insights editor rights allow you to view and edit all slide shows
            </template>
            <template v-else>You can only view slide shows, apply for editor rights in the IT portal</template>
          </div>
        </v-col>

        <v-col class="d-flex justify-end align-top" cols="12" md="3">
          <v-btn
            color="primary"
            :disabled="!isInsightsEditor || !!(activeProject && !activeProject?.sections?.content)"
            :text="`Create ${$route.meta.create}`"
            @click="$refs.createInsightDialog?.open()"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="
          routeParams.tagFilters.length > 0 ||
          routeParams.stateFilters.length > 0 ||
          routeParams.categoryFilters.length > 0
        "
        class="mt-0 mb-n4"
      >
        <v-col>
          <v-chip
            v-for="filter in routeParams.tagFilters"
            :key="filter"
            closable
            class="ma-1"
            @click:close="routeParams.tagFilters = [...routeParams.tagFilters.filter((f) => f !== filter)]"
          >
            <span>{{ (appFeatureTags.find((i) => i.value === filter) || {}).text }}</span>
          </v-chip>

          <v-chip
            v-for="filter in routeParams.categoryFilters"
            :key="filter"
            closable
            class="ma-1"
            @click:close="routeParams.categoryFilters = [...routeParams.categoryFilters.filter((f) => f !== filter)]"
          >
            <span>{{ (categoryItems.find((i) => i.value === filter) || {}).title }}</span>
          </v-chip>

          <v-chip
            v-for="filter in routeParams.stateFilters"
            :key="filter"
            closable
            class="ma-1"
            @click:close="routeParams.stateFilters = [...routeParams.stateFilters.filter((f) => f !== filter)]"
          >
            <span>{{ (stateItems.find((i) => i.value === filter) || {}).title }}</span>
          </v-chip>
        </v-col>
      </v-row>

      <v-row v-if="activeProject && !activeProject?.sections?.content">
        <v-col class="d-flex justify-center" cols="12">
          <v-divider />

          <v-alert rounded type="info" class="pa-8 opacity-80" position="absolute" width="50%" style="top: 45%">
            Selected project does not use app insights, you can select another project from the top bar or unselect the
            current project to see all the existing app insights.
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col>
          <InsightsList
            v-model:active-insight="activeInsight"
            :store="store"
            :insights-path="insightsPath"
            :sort-order="routeParams.sortOrder"
            :tag-filters="routeParams.tagFilters"
            :state-filters="routeParams.stateFilters"
            :category-filters="routeParams.categoryFilters"
            :insight-id-filters="routeParams.search ? searchInsightIds : null"
          />
        </v-col>
      </v-row>
    </v-container>

    <CreateInsight
      ref="createInsightDialog"
      :store="store"
      :insights-path="insightsPath"
      :category-items="categoryItems"
    />

    <FindReplace v-model="findReplaceDialog" :insights="searchResults" :insights-path="insightsPath" />
    <ExportInsights
      v-model="exportInsightsDialog"
      :insight-ids="searchResultIds"
      :insight-collection="insightCollection"
    />
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { mergeProps } from 'vue'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce, RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { insightCategories, insightStates, slideshowCategories, tipthemesCategories } from '#views/insights/constants'

  import { AppStore, InsightsStore, ProjectsStore, SearchStore, SlideshowsStore, TipthemesStore } from '#stores'

  import { Dialog, Insight } from '#types'

  @Component({})
  class InsightsView extends mixins(RouteParams) {
    @Prop() public store!: string

    @Prop() public insightsPath!: string

    declare public $refs: {
      createInsightDialog: Dialog
    }

    public routeParams = {
      search: '' as string,
      sortOrder: '' as string,
      tagFilters: [] as string[],
      stateFilters: [] as string[],
      categoryFilters: [] as string[],
    }

    public viewMenu = false
    public filterMenu = false
    public findReplaceDialog = false
    public exportInsightsDialog = false

    public activeInsight: string | null = null

    public mergeProps = mergeProps

    public readonly useClipboard = useClipboard()

    private readonly appStore = new AppStore()
    private readonly searchStore = new SearchStore()
    private readonly projectsStore = new ProjectsStore()

    // Do not remove these, they are accessed dynamically based on the store prop
    private readonly insightsStore = new InsightsStore()
    private readonly tipthemesStore = new TipthemesStore()
    private readonly slideshowsStore = new SlideshowsStore()

    public get searching() {
      return this.searchStore.loading
    }

    public get stateItems() {
      return insightStates
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    public get inTipthemes() {
      return this.appStore.inTipthemes
    }

    public get inSlideshows() {
      return this.appStore.inSlideshows
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get insightCollection() {
      if (this.inTipthemes) {
        return 'tipthemes'
      } else if (this.inSlideshows) {
        return 'slideshows'
      } else {
        return 'insights'
      }
    }

    public get searchResults(): any {
      return this[`${this.store}Store` as keyof InsightsView].searchResults
    }

    public get searchResultIds(): any {
      return this.searchResults.map((item: Insight) => item.id)
    }

    public get categoryItems() {
      return this.inSlideshows ? slideshowCategories : this.inTipthemes ? tipthemesCategories : insightCategories
    }

    public get appFeatureTags() {
      return this.insightsStore.featureTags
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get searchInsightIds() {
      return this.searchStore.results
    }

    @Watch('routeParams.search')
    protected onSearchChanged() {
      this.updateSearch()
    }

    public mounted() {
      this.updateSearch()

      this.insightsStore.fetchStats()
    }

    @Debounce(500)
    public updateSearch() {
      this.searchStore.updateSearch(this.routeParams.search)
    }
  }

  export default toNative(InsightsView)
</script>
