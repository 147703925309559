<template>
  <v-app-bar>
    <v-app-bar-title>Priorities Sim / Insights ({{ searchResults.length }})</v-app-bar-title>

    <v-spacer />

    <v-text-field
      v-model="routeParams.search"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      :placeholder="'Type to filter insights...'"
    />
    <v-menu v-model="filterMenu" offset="8" min-width="230" max-height="500" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-filter-variant-plus" :color="props.value ? 'primary' : ''" />
      </template>

      <v-list>
        <v-list-subheader>Select additional filters</v-list-subheader>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Feature tags" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in appFeatureTags"
              :key="item.value"
              :title="item.text"
              :disabled="routeParams.tagFilters.includes(item.value)"
              @click="routeParams.tagFilters = [...routeParams.tagFilters, item.value]"
            />
          </v-list>
        </v-menu>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Insight category" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in categoryItems"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.categoryFilters.includes(item.value)"
              @click="routeParams.categoryFilters = [...routeParams.categoryFilters, item.value]"
            />
          </v-list>
        </v-menu>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Insight group ID" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in groupIdItems"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.groupIdFilters.includes(item.value)"
              @click="routeParams.groupIdFilters = [...routeParams.groupIdFilters, item.value]"
            />
          </v-list>
        </v-menu>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Publishing state" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in stateItems"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.stateFilters.includes(item.value)"
              @click="routeParams.stateFilters = [...routeParams.stateFilters, item.value]"
            />
          </v-list>
        </v-menu>

        <v-divider />

        <v-list-item
          prepend-icon="mdi-close-circle-outline"
          title="Clear all filters"
          :disabled="
            !routeParams.tagFilters.length &&
            !routeParams.stateFilters.length &&
            !routeParams.categoryFilters.length &&
            !routeParams.groupIdFilters.length
          "
          @click="
            ((routeParams.tagFilters = []),
            (routeParams.stateFilters = []),
            (routeParams.categoryFilters = []),
            (routeParams.groupIdFilters = []),
            (filterMenu = false))
          "
        />
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Priority simulator allows tweaking priorities</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isInsightsEditor">Your insight editor rights allow you to view and edit priorities</template>
          <template v-else>You can only view priorities, apply for editor rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn text="Run simulator" color="primary" :disabled="true" />
      </v-col>
    </v-row>

    <v-row
      v-if="
        routeParams.tagFilters.length > 0 ||
        routeParams.stateFilters.length > 0 ||
        routeParams.groupIdFilters.length > 0 ||
        routeParams.categoryFilters.length > 0
      "
      class="mt-0 mb-n4"
    >
      <v-col>
        <v-chip
          v-for="filter in routeParams.tagFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.tagFilters = [...routeParams.tagFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (appFeatureTags.find((i) => i.value === filter) || {}).text }}</span>
        </v-chip>

        <v-chip
          v-for="filter in routeParams.categoryFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.categoryFilters = [...routeParams.categoryFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (categoryItems.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>

        <v-chip
          v-for="filter in routeParams.groupIdFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.groupIdFilters = [...routeParams.groupIdFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (groupIdItems.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>

        <v-chip
          v-for="filter in routeParams.stateFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.stateFilters = [...routeParams.stateFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (stateItems.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-if="activeProject && !activeProject?.sections?.content">
      <v-col class="d-flex justify-center" cols="12">
        <v-divider />

        <v-alert rounded type="info" class="pa-8 opacity-80" position="absolute" width="50%" style="top: 45%">
          Selected project does not use insight priorities, you can select another project from the top bar or unselect
          the current project to see all the existing insight priorities.
        </v-alert>
      </v-col>
    </v-row>

    <template v-else>
      <v-row class="d-flex flew-row align-center justify-start">
        <v-col cols="4">
          <v-tabs v-model="activeTab" background-color="transparent" grow>
            <v-tab v-for="tab in tabs" :key="tab.id" :value="tab.id">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-spacer />

        <v-col v-if="inCalculatorView" class="d-flex flew-row align-center justify-end">
          <v-btn tile small variant="text" color="primary" @click.stop="$refs.contributorsPanelRef?.open()">
            Contributor settings ({{ contributorValues.length }})
          </v-btn>

          <span class="overline ml-2 mr-4">|</span>

          <v-btn tile small variant="text" color="primary" @click.stop="$refs.weightsDialogRef?.open()">
            Weight settings ({{ defaultPriorityWeights.length }})
          </v-btn>

          <span class="overline ml-2 mr-4">|</span>

          <v-btn tile small variant="text" color="primary" @click.stop="$refs.settingsDialogRef?.open()">
            Other settings
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <PriorityEditTable
            :table-toggle="activeTab"
            :hacks-toggle="ignoreHacksToggle"
            :contributors-toggle="enableContributorsToggled"
            :weights-toggle="enableWeightsToggled"
            :stored-contributors="contributorValues"
            :stored-weights="storedWeightValues"
            :update-needed="changesMade"
            :store="store"
            :insights-path="insightsPath"
            :tag-filters="routeParams.tagFilters"
            :state-filters="routeParams.stateFilters"
            :category-filters="routeParams.categoryFilters"
            :group-id-filters="routeParams.groupIdFilters"
            :insight-id-filters="routeParams.search ? searchInsightIds : null"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>

  <ContributorsPanel
    ref="contributorsPanelRef"
    :stored-contributors="contributorValues"
    :stored-enable-contributors-toggle="enableContributorsToggled"
    @toggle-enable-contributors="toggleEnableContributors($event)"
    @update-saved-contributors="updateSavedContributors($event)"
  />
  <WeightsDialog
    ref="weightsDialogRef"
    :stored-weights="storedWeightValues"
    :stored-enable-weights-toggle="enableWeightsToggled"
    @update-saved-weights="updateSavedWeights($event)"
    @toggle-enable-weights="toggleEnableWeights($event)"
  />
  <SettingsDialog
    ref="settingsDialogRef"
    :stored-ignore-hacks="ignoreHacksToggle"
    @toggle-ignore-hacks="toggleIgnoreHacks($event)"
  />
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce, RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { insightCategories, insightGroups, insightStates } from '#views/insights/constants'
  import { contributorList, priorityWeights, templateContributors } from '#views/priorities/constants'

  import { AppStore, InsightsStore, ProjectsStore, SearchStore } from '#stores'

  import { ContributorScore, Dialog, Insight, Panel, Weight } from '#types'

  @Component({})
  class PrioritiesView extends mixins(RouteParams) {
    @Prop() public section!: string
    @Prop() public store!: string
    @Prop() public insightsPath!: string

    public filterMenu = false
    public insightCount = 0
    public defaultContributors: any = templateContributors
    public activeTab = 'FILTER'
    public tabs = {
      FILTER: { id: 'FILTER', name: 'Insight settings' },
      CALCULATOR: { id: 'CALCULATOR', name: 'Priority Calculator' },
    }

    public panelActive = false

    public env = import.meta.env.VITE_ENV

    public groupIdItems = insightGroups
    public contributors = contributorList

    public defaultsContributorValues = templateContributors
    public defaultPriorityWeights: Weight[] = priorityWeights
    public storedWeightValues = this.getStoredWeights() ?? priorityWeights
    public enableWeightsToggled = this.getStoredWeightToggleValue() ?? false
    public contributorValues = this.getStoredContributors() ?? templateContributors
    public enableContributorsToggled = this.getStoredContributorsToggleValue() ?? false
    public ignoreHacksToggle = this.getStoredHackValue() ?? false
    public changesMade: boolean = false
    public routeParams = {
      search: '' as string,
      tagFilters: [] as string[],
      stateFilters: [] as string[],
      categoryFilters: [] as string[],
      groupIdFilters: [] as string[],
    }
    declare public $refs: {
      contributorsPanelRef: Panel
      weightsDialogRef: Dialog
      settingsDialogRef: Dialog
    }

    private readonly appStore = new AppStore()
    private readonly searchStore = new SearchStore()
    private readonly insightsStore = new InsightsStore()
    private readonly projectsStore = new ProjectsStore()

    public get stateItems() {
      return insightStates
    }

    public get categoryItems() {
      return insightCategories
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get searchResults() {
      const searchResults: Insight[] = this[`${this.store}Store` as keyof PrioritiesView].searchResults

      return searchResults
    }

    public get appFeatureTags() {
      return this.insightsStore.featureTags
    }

    public get searchInsightIds() {
      return this.searchStore.results
    }

    public get inCalculatorView() {
      return this.activeTab === 'CALCULATOR'
    }

    public get savedContributors() {
      return this.defaultContributors
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    @Watch('routeParams.search')
    protected onSearchChanged() {
      this.updateSearch()
    }

    public updateSavedContributors(contributors: any[]) {
      this.contributorValues = contributors
      this.changesMade = true
    }

    public updateSavedWeights(weights: Weight[]) {
      this.storedWeightValues = weights
      this.changesMade = true
    }

    public toggleEnableWeights(weighttoggle: boolean) {
      this.enableWeightsToggled = weighttoggle
    }

    public toggleEnableContributors(contributortoggle: boolean) {
      this.enableContributorsToggled = contributortoggle
    }

    public toggleIgnoreHacks(hacktoggle: boolean) {
      this.ignoreHacksToggle = hacktoggle
    }

    public updateDefaultContributors(newContributors: any[]) {
      this.defaultContributors = newContributors
      this.panelActive = false
    }

    public updateInsightCount(count: number) {
      this.insightCount = count
    }

    public mounted() {
      this.updateSearch()
    }

    private getStoredContributors(): ContributorScore[] {
      let saved = JSON.parse(localStorage.getItem('UserDefinedContributors') ?? '{}')
      if (localStorage.getItem('UserDefinedContributors') == null) {
        saved = templateContributors
      }
      return saved
    }

    private getStoredWeights(): Weight[] {
      let saved = JSON.parse(localStorage.getItem('UserDefinedWeights') ?? '{}')
      if (localStorage.getItem('UserDefinedWeights') == null) {
        saved = this.defaultPriorityWeights
      }
      return saved
    }

    private getStoredWeightToggleValue(): boolean {
      let saved = JSON.parse(localStorage.getItem('UserDefinedWeightToggle') ?? '{}')
      if (localStorage.getItem('UserDefinedWeightToggle') == null) {
        saved = false
      }
      return saved
    }

    private getStoredContributorsToggleValue(): boolean {
      let saved = JSON.parse(localStorage.getItem('UserDefinedContributorsToggle') ?? '{}')
      if (localStorage.getItem('UserDefinedContributorsToggle') == null) {
        saved = false
      }
      return saved
    }

    private getStoredHackValue(): boolean {
      const item = localStorage.getItem('UserDefinedIgnoreHacks')
      let saved = JSON.parse(localStorage.getItem('UserDefinedIgnoreHacks') ?? '{}')
      if (item == null || undefined) {
        saved = false
      }
      return saved
    }

    @Debounce(500)
    public updateSearch() {
      this.searchStore.updateSearch(this.routeParams.search)
    }
  }

  export default toNative(PrioritiesView)
</script>

<style lang="scss" scoped>
  :deep(.theme--light.v-tabs > .v-tabs-bar) {
    background: none;
  }

  :deep(.v-tab) {
    text-transform: none !important;
  }

  :deep(.v-data-table) {
    .v-data-table__wrapper {
      table {
        thead,
        tbody {
          tr:not(.v-data-table__empty-wrapper) {
            &.v-data-table__expanded.v-data-table__expanded__row {
              background: #ffffff;
            }

            &.v-data-table__expanded.v-data-table__expanded__content {
              box-shadow: none;
              background: #eeeeee;
            }
          }
        }
      }
    }
  }
</style>
