// sort-imports-ignore

import App from '#App.vue'

import router from '#router'

import { createApp } from 'vue'

import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'

import { initializeApp } from 'firebase/app'

import { getAuth } from 'firebase/auth'

import { getStorage } from 'firebase/storage'

import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import { getRemoteConfig } from 'firebase/remote-config'

import 'vuetify/styles'

import '@mdi/font/css/materialdesignicons.css'
import '@jouzen/outo-toolkit-vuetify/style.css'
import 'diff2html/bundles/css/diff2html.min.css'
import 'vue3-organization-chart/dist/orgchart.css'

import '@jouzen/outo-toolkit-vuetify/vuetify.scss'
import '@jouzen/outo-toolkit-vuetify/markdown.scss'

import { VCalendar } from 'vuetify/labs/VCalendar'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

import {
  defaults,
  activateRemoteConfig,
  createPiniaAxiosPlugin,
  createPiniaFirebasePlugin,
  createVueAtlassianPlugin,
  createVueConfirmPlugin,
  createVueDayjsPlugin,
  createVueFirebasePlugin,
  createVueSentryPlugin,
} from '@jouzen/outo-toolkit-vuetify'

// TODO: Temporal vapp naming to avoid conflict with IE
const vapp = createApp(App)
const pinia = createPinia()

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        dark: true,
        colors: {
          info: '#42A5F5', // blue-lighten-1 (material palette)
          error: '#EF5350', // red-lighten-1 (material palette)
          success: '#66BB6A', // green-lighten-1 (material palette)
          warning: '#FF9800', // orange-lighten-1 (material palette)
          surface: '#373737', // lighten(#181818, 12%) (custom color)
          primary: '#5C6BC0', // <color>-lighten-1 (pink | purple | indigo)
          secondary: '#0D1126', // color-mix(#000000, 100%, <primary>, 50%)
          background: '#1B1C1F', // color-mix(#181818, 100%, <primary>, 5%)
        },
        variables: {
          'high-emphasis-opacity': 1.0,
        },
      },
      light: {
        dark: false,
        colors: {
          info: '#42A5F5', // blue-lighten-1 (material palette)
          error: '#EF5350', // red-lighten-1 (material palette)
          success: '#66BB6A', // green-lighten-1 (material palette)
          warning: '#FF9800', // orange-lighten-1 (material palette)
          surface: '#FFFFFF', // lighten(#F8F8F8, 2.5%) (custom color)
          primary: '#5C6BC0', // <color>-lighten-1 (pink | purple | indigo)
          secondary: '#DCE1F4', // color-mix(#ffffff, <primary-color-hex>, 25%)
          background: '#F4F4F7', // color-mix(#f8f8f8, <primary-color-hex>, 2.5%)
        },
        variables: {
          'high-emphasis-opacity': 1.0,
        },
      },
    },
  },
  defaults,
  components: {
    VCalendar,
    VTimePicker,
  },
})

const firebase = initializeApp({
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
})

vapp.use(pinia)
vapp.use(router)
vapp.use(vuetify)

vapp.use(createVueDayjsPlugin())
vapp.use(
  createVueSentryPlugin({
    router,

    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: process.env.APP_RELEASE_VERSION,
    environment: import.meta.env.VITE_APP_ENV,
    tracingOrigin: import.meta.env.VITE_API_URL,
  }),
)
vapp.use(
  createVueFirebasePlugin({
    firebase: firebase,

    analytics: getAnalytics(firebase),
    remoteConfig: getRemoteConfig(firebase),
  }),
)
vapp.use(
  createVueAtlassianPlugin({
    component: import.meta.env.VITE_JIRA_COMPONENT_ID,
    environment: import.meta.env.VITE_APP_ENV,
  }),
)

vapp.use(createVueConfirmPlugin(), vuetify)

pinia.use(
  createPiniaAxiosPlugin({
    auth: getAuth(firebase),

    url: import.meta.env.VITE_API_URL,

    envs: {
      test: import.meta.env.VITE_API_URL_TEST,
      stage: import.meta.env.VITE_API_URL_STAGE,
    },

    version: process.env.APP_RELEASE_VERSION,
  }),
)
pinia.use(
  createPiniaFirebasePlugin({
    firebase: firebase,

    fbstorage: getStorage(firebase),
    firestore: getFirestore(firebase),

    remoteConfig: getRemoteConfig(firebase),

    functions: getFunctions(firebase, 'europe-west1'),
  }),
)

activateRemoteConfig({
  remoteConfig: getRemoteConfig(firebase),

  configDefaults: {
    maintenance: false,
    appReleaseTool: false,
  },
})
  .then(() => {
    console.info('Firebase initialized')
  })
  .catch(() => {
    console.error('Failed to initialize firebase')
  })
  .finally(() => {
    vapp.mount('#app')
  })

/**
 * This is a custom directive that converts the input value of textarea or input to lowercase
 *
 * Usage:
 * - <v-textarea v-model="model" v-lowercase />
 * - <v-text-field v-model="model" v-lowercase />
 */
vapp.directive('lowercase', {
  updated: (el: HTMLElement) => {
    const element = el.querySelector('input') || el.querySelector('textarea')

    if (element?.value) {
      const lowercaseValue = element.value.toLowerCase()

      if (element.value !== lowercaseValue) {
        element.value = lowercaseValue
        // Dispatch input event to trigger v-model update
        element.dispatchEvent(new Event('input'))
      }
    }
  },
})
