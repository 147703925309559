<template>
  <v-dialog v-model="isOpen" max-width="1200px">
    <v-card>
      <v-card-title>Open AI system settings</v-card-title>
      <v-card-text v-if="errorSaving">
        <v-alert class="mb-4" type="error" text="Failed to save settings, please try again" />
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-textarea
              :disabled="!isInsightsEditor"
              rows="11"
              :model-value="settings.instructions"
              label="Instructions"
              auto-grow
              @update:model-value="settings.instructions = $event"
            />
          </v-col>
          <v-col>
            <v-row>
              <v-select
                :disabled="!isInsightsEditor"
                class="mt-3"
                :model-value="settings.model"
                label="Model"
                :items="['gpt-4o', 'gpt-4o-mini', 'gpt-3.5-turbo', 'gpt-4-turbo', 'gpt-4']"
              />
            </v-row>
            <v-row>
              <v-text-field
                :model-value="settings.temperature"
                :disabled="!isInsightsEditor"
                label="Temperature"
                type="number"
                @update:model-value="settings.temperature = Number($event)"
              />
            </v-row>
            <v-row>
              <v-text-field
                :disabled="!isInsightsEditor"
                :model-value="settings.top_p"
                label="Top P"
                type="number"
                @update:model-value="settings.top_p = Number($event)"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="caption">WARNING: Setting changes are global, and non reversible</div>

        <v-spacer />
        <v-btn color="primary" :text="settingsHaveChanged() ? 'Cancel' : 'Close'" @click="cancelClick()" />
        <v-btn
          color="primary"
          text="Save"
          :loading="savingSettings"
          :disabled="!unsavedSettingsValid || !settingsHaveChanged() || !isInsightsEditor"
          @click="okClick()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { getApp } from 'firebase/app'
  import { getFunctions, httpsCallable } from 'firebase/functions'

  import { AppStore } from '#stores'

  @Component({})
  class DaphneSettingsDialog extends Vue {
    public isOpen = false
    public errorSaving = false
    public savingSettings = false

    public get unsavedSettingsValid() {
      return this.settings.temperature && this.settings.model && this.settings.top_p && this.settings.instructions
    }

    public settings: {
      temperature: number | null
      model: string | null
      top_p: number | null
      instructions: string | null
    } = {
      temperature: null,
      model: null,
      top_p: null,
      instructions: null,
    }

    public unsavedSettings: {
      temperature: number | null
      model: string | null
      top_p: number | null
      instructions: string | null
    } = {
      temperature: null,
      model: null,
      top_p: null,
      instructions: null,
    }

    private readonly appStore = new AppStore()

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public settingsHaveChanged() {
      return !(
        (this.settings.instructions ? this.settings.instructions === this.unsavedSettings.instructions : false) &&
        (this.settings.temperature ? this.settings.temperature === this.unsavedSettings.temperature : false) &&
        (this.settings.model ? this.settings.model === this.unsavedSettings.model : false) &&
        (this.settings.top_p ? this.settings.top_p === this.unsavedSettings.top_p : false)
      )
    }

    public async open() {
      const functions = getFunctions(getApp(), 'europe-west1')
      const response: any = await httpsCallable(functions, 'fetchSavedSettingsForDaphne', { timeout: 100000 })({})

      this.settings = response.data
      this.unsavedSettings = { ...this.settings }
      this.isOpen = true
      this.savingSettings = false
    }

    public cancelClick() {
      this.isOpen = false
    }

    public async okClick() {
      this.errorSaving = false
      this.savingSettings = true
      const functions = getFunctions(getApp(), 'europe-west1')

      const response: any = await httpsCallable(functions, 'updateSavedSettingsForDaphne', { timeout: 100000 })(
        this.settings,
      )
      this.savingSettings = false
      if (response && response.data.success) {
        this.isOpen = false
      } else {
        this.errorSaving = true
      }
    }
  }

  export default toNative(DaphneSettingsDialog)
</script>
