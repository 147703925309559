import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

import { WaltariProject } from '#types'

let unsubscribeProjects: Unsubscribe | undefined = undefined

@Store
export class ProjectsStore extends Pinia {
  public loading = true

  public project: WaltariProject | null = null

  public projects: WaltariProject[] = []

  public async setProject(project: WaltariProject | null) {
    this.project = project

    localStorage.OuraProject = project?.id ?? ''
  }

  public async updateProject(project: WaltariProject) {
    const updateKey = project.createdAt ? 'updatedAt' : 'createdAt'

    const updatedProject = {
      ...project,
      [updateKey]: serverTimestamp(),
    }

    await setDoc(doc(getFirestore(), `/projects/${project.id}`), updatedProject, { merge: true })
  }

  public async resetChangesAtTimestamp(project: any) {
    const change = {
      changesAt: project?.releasedAt || project?.createdAt || '',
    }

    await updateDoc(doc(getFirestore(), `/projects/${project.id}`), change)
  }

  public async subscribeToProjects() {
    if (!unsubscribeProjects) {
      unsubscribeProjects = onSnapshot(query(collection(getFirestore(), '/projects')), (snap) => {
        const projects: any = sortBy(
          snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            // TODO: Temporal migration code for project types, should run mass migration at some point
            sections: {
              content: doc.data().sections?.content ?? (doc.data().sections?.contents || doc.data().sections?.insights),
              features: doc.data().sections?.features,
            },
          })),
          [(o: any) => o.name || ''],
        )

        const project = projects.find((p: WaltariProject) => p.id === localStorage.OuraProject)

        if (project) {
          this.project = project
        }

        this.projects = projects

        this.loading = false
      })
    }
  }
  public async unsubscribeFromProjects() {
    this.project = null

    this.projects = []

    if (unsubscribeProjects) {
      unsubscribeProjects()

      unsubscribeProjects = undefined
    }
  }
}
