<template>
  <div v-for="(parameter, i) in edited.parameters" :key="i">
    <v-row align="center" class="parameters mb-n2">
      <v-col>
        <v-text-field
          hide-details
          label="Name"
          density="compact"
          :single-line="i > 0"
          :model-value="parameter.name"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          @update:model-value="parameter.name = snakeCase($event.trim())"
        />
      </v-col>
      <v-col class="px-0" style="max-width: 105px">
        <v-select
          hide-details
          return-object
          label="Type"
          density="compact"
          :single-line="i > 0"
          :items="parameterTypes"
          :model-value="parameterTypes.find((p) => p.value === parameter.type?.oneOf?.$case)"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          @update:model-value="parameter.type = { ...$event.type } as any"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-if="parameter.type?.oneOf?.$case === 'boolean'"
          readonly
          hide-details
          density="compact"
          label="Default value"
          :single-line="i > 0"
          :rules="[validateValueType(parameter)]"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          :model-value="parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue"
          @click="
            parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue =
              !parameter.type.oneOf[parameter.type.oneOf.$case]
          "
        />
        <v-select
          v-else-if="parameter.type?.oneOf?.$case === 'enum'"
          hide-details
          density="compact"
          label="Default value"
          no-data-text="No members set"
          :single-line="i > 0"
          :items="parameter.type.oneOf[parameter.type.oneOf.$case].values || []"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          :model-value="parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue"
          @update:model-value="transformParamValue(parameter, $event.toLowerCase())"
        />
        <v-text-field
          v-else-if="parameter.type?.oneOf?.$case === 'fixedPoint'"
          hide-details
          density="compact"
          label="Default value"
          :single-line="i > 0"
          :rules="[validateValueType(parameter)]"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          :model-value="
            fractionNumbers[i] || fixedPointToString(parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue)
          "
          @update:model-value="fractionNumbers[i] = $event"
          @update:focused="$event ? null : transformParamValue(parameter, fractionNumbers[i], i)"
        />
        <v-text-field
          v-else-if="parameter.type?.oneOf?.$case === 'duration'"
          hide-details
          persistent-placeholder
          type="number"
          density="compact"
          label="Default value"
          placeholder="In seconds"
          :single-line="i > 0"
          :rules="[validateValueType(parameter)]"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          :model-value="
            parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue!.seconds
              ? parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue!.seconds
              : null
          "
          @update:model-value="parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue!.seconds = $event || '0'"
        />
        <v-text-field
          v-else
          hide-details
          density="compact"
          label="Default value"
          :single-line="i > 0"
          :rules="[validateValueType(parameter)]"
          :disabled="
            (i === 0 || !!edited.metadata?.changeRecord?.createdAt || edited.parameters[i].deprecated) &&
            i < original.parameters.length
          "
          :model-value="(parameter.type!.oneOf as any)[parameter.type!.oneOf!.$case].defaultValue"
          @update:model-value="transformParamValue(parameter, $event)"
        />
      </v-col>
      <v-col style="max-width: 24px; padding: 0" class="ml-n2 mr-6">
        <v-tooltip
          v-if="!edited.metadata?.changeRecord?.createdAt || i >= original.parameters.length"
          text="Delete parameter"
        >
          <template #activator="{ props }">
            <v-btn v-bind="props" icon="mdi-delete" :disabled="i === 0" @click="emitDelete(i)" />
          </template>
        </v-tooltip>

        <v-tooltip v-else :text="edited.parameters[i].deprecated ? 'Deprecated parameter' : 'Deprecate parameter'">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              icon="mdi-archive"
              :color="edited.parameters[i].deprecated ? 'error' : 'grey'"
              :disabled="i === 0 || !original.parameters[i] || !!original.parameters[i].deprecated"
              @click="toggleDeprecateParameter(edited.parameters[i])"
            />
          </template>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="parameter.type?.oneOf?.$case === 'enum'" class="parameters mt-n4 mb-n2">
      <v-col class="d-flex flex-row align-center justify-center">
        <v-icon>mdi-format-list-bulleted</v-icon>

        <v-text-field
          v-model.lower="enumMembers[i]"
          single-line
          hide-details
          persistent-placeholder
          class="ml-3"
          density="compact"
          label="Add enum members"
          placeholder="Enter enum member to add or remove"
          :disabled="edited.parameters[i].deprecated"
          @keyup:enter="appendEnumMember(parameter, i)"
          @click:append="appendEnumMember(parameter, i)"
        />
      </v-col>

      <v-col style="max-width: 24px; padding: 0" class="ml-n2 mr-6">
        <v-tooltip
          v-if="!enumMembers[i] || !parameter.type.oneOf[parameter.type.oneOf.$case]?.values?.includes(enumMembers[i])"
          text="Add enum member to the list"
        >
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              class="mt-2 mr-n3"
              icon="mdi-plus"
              :disabled="!enumMembers[i] || edited.parameters[i].deprecated"
              @click="appendEnumMember(parameter, i)"
            />
          </template>
        </v-tooltip>

        <v-tooltip v-else text="Remove enum member from the list">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              class="mt-2 mr-n3"
              icon="mdi-minus"
              :disabled="edited.parameters[i].deprecated || !isEnumMemberNew(parameterTypes, enumMembers[i])"
              @click="removeEnumMember(parameter, i)"
            />
          </template>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row
      v-if="
        parameter.name !== 'enabled' ||
        !parameter.description ||
        (original.parameters[i] && !original.parameters[i].description)
      "
      class="parameters mt-n4 mb-n2"
    >
      <v-col class="d-flex flex-row align-center justify-center">
        <v-icon :color="edited.parameters[i].deprecated ? 'grey' : ''">mdi-information-variant</v-icon>

        <v-text-field
          single-line
          hide-details
          class="ml-3"
          density="compact"
          label="Short description of the purpose *"
          :disabled="edited.parameters[i].deprecated"
          :model-value="edited.parameters[i].deprecated ? 'Deprecated, should not be used' : parameter.description"
          @update:model-value="parameter.description = $event"
        />
      </v-col>

      <v-col style="max-width: 24px; padding: 0" class="ml-n2 mr-6">
        <v-tooltip text="Toggle parameter tracking in Amplitude">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              class="mt-2 mr-n3"
              icon="mdi-google-analytics"
              :disabled="edited.parameters[i].deprecated"
              :color="parameter.visibility === AnalyticsVisibility.EXPORTED ? 'primary' : 'grey'"
              @click="toggleAmplitudeVisibility(parameter)"
            />
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import slug from 'slug'

  import { snakeCase } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Context } from '@jouzen/feature-mgmt-api/metadata'
  import { Parameter_AnalyticsVisibility } from '@jouzen/feature-mgmt-api/parameter'

  import { parameterTypes } from '#views/features/constants'

  import { fixedPointToString, stringToFixedPoint } from '#views/features/utilities'

  import { Feature } from '#types'

  @Component({})
  class FeatureParams extends Vue {
    @Prop() public edited!: Feature
    @Prop() public original!: Feature

    public enumMembers: string[] = []
    public fractionNumbers: string[] = []

    public readonly slug = slug

    public readonly snakeCase = snakeCase

    public readonly fixedPointToString = fixedPointToString

    public readonly parameterTypes = parameterTypes

    public readonly MetadataContext = Context
    public readonly AnalyticsVisibility = Parameter_AnalyticsVisibility

    @Emit('delete')
    public emitDelete(index: number) {
      return index
    }

    public isEnumMemberNew(parameter: any, value: string) {
      const original = this.original.parameters.find((p) => p.name === parameter.name)

      return (
        !original?.type ||
        (original.type.oneOf?.$case === 'enum' &&
          !original.type.oneOf[original.type.oneOf.$case].values.includes(value))
      )
    }

    public appendEnumMember(parameter: any, index: number) {
      if (parameter.type.oneOf.$case === 'enum' && this.enumMembers[index]) {
        parameter.type.oneOf[parameter.type.oneOf.$case].values.push(slug(this.enumMembers[index].trim(), '_'))

        if (!parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue) {
          parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue =
            parameter.type.oneOf[parameter.type.oneOf.$case].values[0]
        }

        delete this.enumMembers[index]
      }
    }

    public removeEnumMember(parameter: any, index: number) {
      if (parameter.type.oneOf.$case === 'enum' && this.enumMembers[index]) {
        parameter.type.oneOf[parameter.type.oneOf.$case].values = parameter.type.oneOf[
          parameter.type.oneOf.$case
        ].values.filter((e: string) => e !== this.enumMembers[index])

        if (parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue === this.enumMembers[index]) {
          parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue =
            parameter.type.oneOf[parameter.type.oneOf.$case].values[0] || ''
        }

        delete this.enumMembers[index]
      }
    }

    public validateValueType(parameter: any) {
      const type = parameter.type.oneOf.$case
      const value = parameter.type.oneOf[type].defaultValue

      if (type === 'boolean') {
        return value === 'true' || value === true || value === 'false' || value === false || `Not valid ${type}`
      } else if (type === 'integer') {
        return !isNaN(value) || `Not valid ${type}`
      }

      return true
    }

    public transformParamValue(parameter: any, value: string, index?: number) {
      switch (parameter.type.oneOf.$case) {
        case 'boolean':
          parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue =
            value === 'true' ? true : value === 'false' ? false : value
          break
        case 'integer':
          parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue = !isNaN(parseInt(value)) ? parseInt(value) : 0
          break
        case 'fixedPoint':
          parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue = stringToFixedPoint(value, 6, true)

          delete this.fractionNumbers[index!]
          break
        default:
          parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue = value
      }
    }

    public toggleDeprecateParameter(parameter: any) {
      parameter.deprecated = !parameter.deprecated
    }

    public toggleAmplitudeVisibility(parameter: any) {
      parameter.visibility =
        parameter.visibility === Parameter_AnalyticsVisibility.EXPORTED
          ? Parameter_AnalyticsVisibility.HIDDEN
          : Parameter_AnalyticsVisibility.EXPORTED
    }
  }

  export default toNative(FeatureParams)
</script>
