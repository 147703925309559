import { pick, sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'

import { createDefaultFormula } from '#views/formulas/utilities'

import { AppStore, ProjectsStore } from '#stores'

let unsubscribeEvals: Unsubscribe | undefined = undefined
let unsubscribeRules: Unsubscribe | undefined = undefined

@Store
export class FormulasStore extends Pinia {
  public loading = false

  public allEvals: any[] = []
  public allRules: any[] = []

  public get evals() {
    return this.allEvals.filter((r) => r.releasedAt)
  }

  public get rules() {
    return this.allRules.filter((r) => r.releasedAt)
  }

  public get formulas() {
    const inEvals = new AppStore().inEvals
    const inRules = new AppStore().inRules

    return inEvals ? this.allEvals : inRules ? this.allRules : []
  }

  public async fetchRules(ruleIds: string[]) {
    const rules = await getDocs(query(collection(getFirestore(), `/rules`), where('id', 'in', ruleIds)))

    return rules.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as any[]
  }

  public async createFormula(formula: any) {
    const appStore = new AppStore()
    const projectsStore = new ProjectsStore()

    formula.author = appStore.author

    formula.createdAt = serverTimestamp()
    formula.updatedAt = serverTimestamp()

    if (projectsStore.project) {
      formula.project = projectsStore.project.id
    }

    const rootPath = appStore.inEvals ? 'evals' : 'rules'

    return await setDoc(doc(getFirestore(), `/${rootPath}/${formula.id}`), formula)
  }
  public async updateFormula(formula: any) {
    const appStore = new AppStore()

    // TODO: When mappings are updated nothing really changes for the formula
    //       and we should move the mappings out of the content / formula data.

    if (!formula.insights) {
      formula.updatedAt = serverTimestamp()
    }

    const rootPath = appStore.inEvals ? 'evals' : 'rules'

    return await updateDoc(doc(getFirestore(), `/${rootPath}/${formula.id}`), formula)
  }
  public async deleteFormula(formulaId: string) {
    const appStore = new AppStore()

    const rootPath = appStore.inEvals ? 'evals' : 'rules'

    return await deleteDoc(doc(getFirestore(), `/${rootPath}/${formulaId}`))
  }

  public async publishFormula(formula: any) {
    const appStore = new AppStore()

    const rootPath = appStore.inEvals ? 'evals' : 'rules'

    await updateDoc(doc(getFirestore(), `/${rootPath}/${formula.id}`), {
      author: { ...appStore.author, comment: 'Published formula' },
      releasedAt: serverTimestamp(),
    })

    const releaseData = pick(formula, ['id', 'type', 'order', 'category', 'condition', 'moments'])

    if (appStore.inRules) {
      return await setDoc(doc(getFirestore(), `/rollouts/rules/content/${formula.id}`), releaseData)
    } else {
      return await setDoc(doc(getFirestore(), `/rollouts/insights/spotlights/${formula.id}`), releaseData)
    }
  }
  public async unpublishFormula(formulaId: string) {
    const appStore = new AppStore()

    if (appStore.inRules) {
      return await deleteDoc(doc(getFirestore(), `/rollouts/rules/content/${formulaId}`))
    } else {
      return await deleteDoc(doc(getFirestore(), `/rollouts/insights/spotlights/${formulaId}`))
    }
  }

  public async fetchFormulaInsights(projectId?: string) {
    let insights

    if (projectId) {
      insights = await getDocs(query(collection(getFirestore(), `/insights`), where('project', '==', projectId)))
    } else {
      insights = await getDocs(query(collection(getFirestore(), `/insights`)))
    }

    return sortBy(
      insights.docs.map((doc) => ({
        id: doc.id,
        tags: doc.data().tags,
        rule: doc.data().rule,
        rules: doc.data().rules,
        project: doc.data().project,
        spotlight: doc.data().spotlight,
        updatedAt: doc.data().updatedAt,
      })),
      [(o) => o.updatedAt || ''],
    ).reverse()
  }

  public async subscribeToEvals() {
    if (!unsubscribeEvals) {
      this.loading = true

      unsubscribeEvals = onSnapshot(query(collection(getFirestore(), '/evals')), (snap) => {
        if (snap?.docs) {
          const rules: any[] = snap.docs.map((doc) => ({
            ...createDefaultFormula(),
            id: doc.id,
            ...doc.data(),
          }))

          this.allEvals = sortBy(rules, [(o) => o.updatedAt || '']).reverse()
        }
      })

      this.loading = false
    }
  }
  public async unsubscribeFromEvals() {
    if (unsubscribeEvals) {
      unsubscribeEvals()

      unsubscribeEvals = undefined
    }
  }

  public async subscribeToRules() {
    if (!unsubscribeRules) {
      this.loading = true

      unsubscribeRules = onSnapshot(query(collection(getFirestore(), '/rules')), (snap) => {
        if (snap?.docs) {
          const rules: any[] = snap.docs.map((doc) => ({
            ...createDefaultFormula(),
            id: doc.id,
            ...doc.data(),
          }))

          this.allRules = sortBy(rules, [(o) => o.updatedAt || '']).reverse()
        }
      })

      this.loading = false
    }
  }
  public async unsubscribeFromRules() {
    if (unsubscribeRules) {
      unsubscribeRules()

      unsubscribeRules = undefined
    }
  }
}
