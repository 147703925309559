<template>
  <v-navigation-drawer
    order="0"
    width="450"
    location="end"
    elevation="0"
    style="right: 0"
    disable-route-watcher
    disable-resize-watcher
    :model-value="isOpen"
    :temporary="$vuetify.display.mdAndDown"
    @update:model-value="!$event && close()"
  >
    <v-card v-if="!!editedOverrides" class="d-flex flex-column fill-height">
      <v-card-title class="d-flex flex-row shrink">
        <span>
          {{
            !originalOverrides[active]?.metadata?.informative?.labels.rollout &&
            !originalOverrides[active]?.metadata?.informative?.additionalData?.createdAt
              ? 'Create rollout'
              : 'Edit rollout'
          }}
        </span>

        <v-spacer />

        <v-btn icon color="primary" class="mt-n2 mr-n4" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text id="rollout-panel" class="fill-height" style="overflow: auto">
        <div id="rollout-panel-top" />

        <RolloutEditor
          v-if="feature && editedOverrides"
          :active="active"
          :feature="feature"
          :overrides="editedOverrides"
          :references="editedReferences"
          @validate="validateOverrides($event)"
        />

        <div id="rollout-panel-bottom" />
      </v-card-text>

      <v-card-actions class="d-flex flex-row flex-shrink-1 align-center justify-center">
        <v-btn
          class="my-4"
          color="primary"
          :disabled="!isValid || isLoading || !isProjectEditor(editedOverrides[active])"
          :text="
            !originalOverrides[active]?.metadata?.informative?.labels.rollout &&
            !originalOverrides[active]?.metadata?.informative?.additionalData?.createdAt
              ? 'Create'
              : 'Update'
          "
          @click="updateContent()"
        />
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { cloneDeep, isEqual } from 'lodash-es'

  import { useGoTo } from 'vuetify'

  import { Component, Emit, Prop, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ProjectsStore } from '#stores'

  import { Feature, Override, Reference } from '#types'

  @Component({})
  class RolloutPanel extends Vue {
    @Prop() public active!: number

    @Prop() public feature!: Feature
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public isOpen = false
    public isValid = false

    public editedOverrides: Override[] = []
    public originalOverrides: Override[] = []

    public editedReferences: Reference[] = []
    public originalReferences: Reference[] = []

    public readonly isProjectEditor = isProjectEditor

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly featuresStore = new FeaturesStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get existingProjects() {
      return this.projectsStore.projects
    }

    @Emit('open')
    public open() {
      this.initPanel()

      this.isOpen = true

      this.appStore.navDrawer = 'rollout'

      return
    }

    @Emit('close')
    public close() {
      this.appStore.closeNavDrawer('rollout')

      this.isOpen = false

      return
    }

    @Emit('change')
    public emitChange() {
      return this.editedOverrides
    }

    @Watch('appStore.navDrawer', { immediate: true })
    protected navDrawerChanged() {
      if (this.appStore.navDrawer === 'rollout') {
        this.open()
      } else {
        this.close()
      }
    }

    public initPanel() {
      console.info('Editing', this.overrides[this.active])

      this.editedOverrides = cloneDeep(this.overrides)
      this.originalOverrides = cloneDeep(this.overrides)

      this.editedReferences = cloneDeep(this.references)
      this.originalReferences = cloneDeep(this.references)

      if (this.activeProject && !this.originalOverrides[this.active].metadata?.informative?.additionalData?.createdAt) {
        this.editedOverrides[this.active].metadata!.informative!.labels!.project = this.activeProject.id
      }

      this.goTo('#rollout-panel-top', { container: '#rollout-panel' })

      this.emitChange()

      return this.editedOverrides
    }

    public async updateContent() {
      if (
        !this.editedOverrides[this.active]?.metadata?.informative?.labels.rollout &&
        !this.editedOverrides[this.active]?.metadata?.informative?.additionalData?.createdAt
      ) {
        const project = this.existingProjects.find(
          (p: any) => p.id === this.editedOverrides[this.active]?.metadata?.informative?.labels?.project,
        )

        const objectId = this.editedOverrides[this.active]?.metadata?.uid?.split('_').slice(0, -1).join('_')
        const objectName = this.editedOverrides[this.active]?.metadata?.uid
          ?.split('_')
          .slice(0, -1)
          .join('_')
          .toUpperCase()

        this.$confirm(
          'Is the name and project correct?',
          'These can not be changed later so please make sure everything is correct!' +
            '<div>' +
            `<div class="text-overline mt-4">ID</div><div class="text-right">${objectId}</div>` +
            `<div class="text-overline mt-4">Name</div><div class="text-right">${objectName}</div>` +
            `<div class="text-overline mt-4">Project</div><div class="text-right">${project?.name}</div>` +
            '</div>',
        ).then(async (confirmed) => {
          if (confirmed) {
            if (this.editedReferences.length) {
              this.syncAllLinkedRolloutFeatureOverrides()
            }

            await this.featuresStore.updateOverrides(this.feature, this.editedOverrides, this.editedReferences)

            this.$router.push(
              `/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/advanced/${
                this.editedOverrides[this.active].metadata?.uid
              }/0`,
            )

            this.close()
          }
        })
      } else {
        if (this.editedReferences.length) {
          this.syncAllLinkedRolloutFeatureOverrides()
        }

        await this.featuresStore.updateOverrides(this.feature, this.editedOverrides, this.editedReferences)

        this.close()
      }
    }

    public validateOverrides(validForm: boolean) {
      if (!validForm) {
        this.isValid = false
      } else {
        let isValid = true

        const editedOverrides = this.editedOverrides.concat(...this.editedReferences.map((r: any) => r.overrides))
        const originalOverrides = this.originalOverrides.concat(...this.originalReferences.map((r: any) => r.overrides))

        editedOverrides.forEach((override, index) => {
          if (
            !isEqual(editedOverrides, originalOverrides) ||
            !originalOverrides[index]?.metadata?.informative?.additionalData?.createdAt
          ) {
            const checkEmptyValues = function (_key: string, value: any) {
              if (typeof value == 'string') {
                isValid = value ? isValid : false
              } else if (value && typeof value == 'object') {
                Object.keys(value).forEach((key) => {
                  if (!['labels', 'owners', 'displayName'].includes(key)) {
                    checkEmptyValues(key, value[key])
                  }
                })
              }
            }

            Object.keys(override).forEach((key) => {
              const value: any = override[key as keyof typeof override]

              if (['metadata', 'criteria', 'parameters'].includes(key)) {
                checkEmptyValues(key, value)
              }
            })
          } else {
            isValid = false
          }
        })

        this.isValid = isValid
      }
    }

    private syncAllLinkedRolloutFeatureOverrides() {
      this.editedOverrides.forEach((o, i) => {
        this.editedReferences.forEach((reference) => {
          reference.overrides[i].metadata!.uid = o.metadata!.uid

          reference.overrides[i].criteria = cloneDeep(o.criteria)
          reference.overrides[i].rolloutOneOf = cloneDeep(o.rolloutOneOf)
          reference.overrides[i].metadata!.informative = cloneDeep(o.metadata!.informative)
        })
      })
    }
  }

  export default toNative(RolloutPanel)
</script>

<style lang="scss" scoped>
  :deep(.parameters),
  :deep(.predicates) {
    .v-select,
    .v-text-field {
      input,
      label,
      .v-select__selection-text {
        font-size: 14px !important;
      }
    }
  }

  :deep(.predicate-operator) {
    position: absolute;
    left: -4px;
    bottom: 12px;
    font-size: 11px;
  }
</style>
