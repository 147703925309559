<template>
  <v-card>
    <v-card-text>
      <div class="d-flex flex-row mb-4">
        <div class="d-flex flex-row align-center">
          <v-menu location="bottom start" offset="8" :disabled="!isInsightsEditor">
            <template #activator="{ props }">
              <div v-bind="props" class="d-flex flex-nowrap align-center text-no-wrap">
                <div
                  :class="'bg-' + formula.color + '-lighten-3 mr-2 align-end'"
                  style="border-radius: 50%; min-width: 24px; max-width: 24px; min-height: 24px"
                />

                <p class="ma-0">{{ formulaCategories[formula.color].title }}</p>

                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>

            <v-list>
              <v-list-item v-for="color in formulaColors" :key="color" dense @click="updateCategory(color)">
                <template #prepend>
                  <div
                    :class="'bg-' + color + '-lighten-3 mr-4'"
                    style="border-radius: 50%; min-width: 24px; max-width: 24px; min-height: 24px"
                  />
                </template>

                <v-list-item-title>{{ formulaCategories[color].title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-spacer />

        <div class="d-flex flew-row align-center justify-end">
          <v-btn
            color="primary"
            :disabled="!formula.createdAt"
            @click="
              notesDialogRef.open({
                noteId: formula.id,
                noteTitle: formula.title,
                collectionPath: formulasPath,
              })
            "
          >
            <v-icon class="mr-2">mdi-note-text-outline</v-icon>
            Notes ({{ formula.notes ? formula.notes : 0 }})
          </v-btn>

          <span class="overline">|</span>

          <v-menu location="bottom end" offset="8" max-height="500" :disabled="!formula.createdAt">
            <template #activator="{ props }">
              <v-btn v-bind="props" class="mr-n4" color="primary" :disabled="!formula.createdAt">
                <v-icon class="mt-n1 mr-2">mdi-lightbulb-on-outline</v-icon>

                Insights ({{ usedInsights.length }})

                <v-icon class="mt-n1">mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-sheet height="370" rounded="0" style="overflow-y: auto">
                <template v-if="usedInsights.length">
                  <v-list-item
                    v-for="insight in usedInsights"
                    :key="insight.id"
                    :title="insight.id.toUpperCase()"
                    @click="editInsight(insight.id)"
                  />
                </template>
                <template v-else>
                  <v-list-item title="Not used by any insight yet" />
                </template>
              </v-sheet>

              <v-divider class="pb-2" />

              <v-list-item append-icon="mdi-content-copy" @click.stop="useClipboard.copy(usedInsightIds.join('\n'))">
                Copy insights IDs to clipboard
              </v-list-item>

              <v-list-item
                class="text-primary"
                title="Edit insights using this formula"
                append-icon="mdi-pencil-outline"
                :disabled="!formula.releasedAt"
                @click="insightsDialogRef.open(usedInsights, formula.id, formula.title)"
              />
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-text-field v-model="formula.title" label="Title" :readonly="!isInsightsEditor" />

      <v-textarea v-model="formula.description" rows="2" label="Description" :readonly="!isInsightsEditor" />

      <v-autocomplete
        v-model="formula.project"
        item-value="id"
        item-title="name"
        label="Project"
        placeholder="No project selected"
        :items="insightProjects"
      />

      <Statement
        v-model="formula.condition"
        simplify
        :rows="4"
        :readonly="!isInsightsEditor"
        @update:model-value="updateMatches()"
        @update-save-changes="emitUpdateSaveChanges($event)"
      />

      <v-text-field v-if="inEvals" v-model="formula.type" label="Type" :readonly="!isInsightsEditor" />

      <v-text-field
        v-if="inEvals"
        v-model.number="formula.order"
        type="number"
        label="Order"
        :readonly="!isInsightsEditor"
      />

      <div v-if="inEvals" class="d-flex flex-row justify-space-between">
        <div class="text-overline text-no-wrap py-3">Evaluation moments:</div>

        <v-checkbox
          label="All day"
          :model-value="formula?.moments?.length === 3 ? true : false"
          @update:model-value="formula.moments = $event ? ['morning', 'day', 'evening'] : []"
        />

        <v-checkbox v-model="formula.moments" multiple label="Morning" value="morning" />

        <v-checkbox v-model="formula.moments" multiple label="Day" value="day" />

        <v-checkbox v-model="formula.moments" multiple label="Evening" value="evening" />
      </div>

      <v-expansion-panels v-if="inRules" class="mt-2">
        <v-expansion-panel elevation="0">
          <v-expansion-panel-title class="text-overline">
            {{ ruleMatches }} custom rules matches
          </v-expansion-panel-title>

          <v-expansion-panel-text v-if="formula.condition">
            <v-list v-if="!ruleMatches" style="background: transparent">
              <v-list-item title="No matches to this rule in any insights" />
            </v-list>

            <v-list v-if="ruleExactMatches.length">
              <v-list-item v-if="ruleExactMatches.length" class="text-overline">Exact matches</v-list-item>

              <v-list-item
                v-for="(match, index) in ruleExactMatches"
                :key="index"
                @click="historyDialogRef.open('/insights/' + match.id, match.id)"
              >
                <template #title>
                  <div class="d-flex flex-row align-center">
                    <span class="text-button text-black">{{ match.id }}</span>

                    <span
                      v-if="copiedTexts.includes(match.id)"
                      class="ml-2"
                      style="font-weight: normal; font-size: 12px"
                    >
                      (Copied)
                    </span>
                    <v-tooltip v-else top>
                      <template #activator="{ props }">
                        <v-icon
                          v-bind="props"
                          class="ml-2"
                          style="position: relative; top: -1px; font-size: 16px"
                          @click.stop="copyToClipboard(match.id)"
                        >
                          mdi-content-copy
                        </v-icon>
                      </template>

                      Copy ID to clipboard
                    </v-tooltip>

                    <v-spacer />

                    <span class="text-overline text-black">
                      {{ match.state }}
                    </span>
                  </div>
                </template>

                <div>
                  <div v-if="match.tags" class="text-overline text-grey my-n2">{{ humanReadableTags(match) }}</div>

                  <StatementPreview
                    highlight-color="yellow lighten-4"
                    :value="match.rule"
                    :highlight="match.condition.trim().replace('\n', '')"
                    :highlight-text="match.rule.trim().replace('\n', '')"
                  />
                </div>
              </v-list-item>
            </v-list>

            <v-list v-if="ruleAlmostMatches.length">
              <v-list-item v-if="ruleAlmostMatches.length" class="text-overline">Almost matches</v-list-item>

              <v-list-item
                v-for="(match, index) in ruleAlmostMatches"
                :key="index"
                @click="historyDialogRef.open('/insights/' + match.id, match.id)"
              >
                <template #title>
                  <div class="d-flex flex-row align-center">
                    <span class="text-button text-black">{{ match.id }}</span>

                    <span
                      v-if="copiedTexts.includes(match.id)"
                      class="ml-2"
                      style="font-weight: normal; font-size: 12px"
                    >
                      (Copied)
                    </span>
                    <v-tooltip v-else top>
                      <template #activator="{ props }">
                        <v-icon
                          class="ml-2"
                          style="position: relative; top: -1px; font-size: 16px"
                          v-bind="props"
                          @click.stop="copyToClipboard(match.id)"
                        >
                          mdi-content-copy
                        </v-icon>
                      </template>

                      Copy ID to clipboard
                    </v-tooltip>

                    <v-spacer />

                    <span class="text-overline text-black">
                      {{ match.state }}
                    </span>
                  </div>
                </template>

                <div>
                  <div v-if="match.tags" class="text-overline text-grey my-n2">{{ humanReadableTags(match) }}</div>

                  <StatementPreview
                    highlight-color="yellow lighten-4"
                    :value="match.rule"
                    :highlight="match.condition.split('&&').map((rule: string) => rule.trim())"
                    :highlight-text="match.rule"
                  />
                </div>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>

  <NotesDialog ref="notesDialogRef" />
  <HistoryDialog ref="historyDialogRef" />

  <InsightsDialog ref="insightsDialogRef" />
</template>

<script lang="ts">
  import { intersection } from 'lodash-es'

  import { useClipboard } from '@vueuse/core'

  import { Component, Emit, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { formulaColors, insightFormulaCategories, spotlightFormulaCategories } from '#views/formulas/constants'

  import { AppStore, InsightsStore, ProjectsStore } from '#stores'

  import { Dialog, Formula, Insight } from '#types'

  import { simplifyStatement } from '#statement'

  @Component({})
  class CreateFormula extends Vue {
    @Prop() public formula!: Formula

    @Prop() public formulasPath!: string

    public toggleAllExact = false
    public toggleAllAlmost = false

    public copiedTexts: string[] = []

    public ruleExactMatches: any[] = []
    public ruleAlmostMatches: any[] = []
    public rulePartialMatches: any[] = []

    public readonly useClipboard = useClipboard()

    public readonly formulaColors = formulaColors

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly insightsStore = new InsightsStore()

    @Ref() public readonly notesDialogRef!: Dialog
    @Ref() public readonly historyDialogRef!: Dialog
    @Ref() public readonly insightsDialogRef!: Dialog

    public get inEvals() {
      return this.appStore.inEvals
    }

    public get inRules() {
      return this.appStore.inRules
    }

    public get allInsights() {
      return this.insightsStore.allInsights
    }

    public get ruleMatches() {
      return this.ruleExactMatches.length + this.ruleAlmostMatches.length + this.rulePartialMatches.length
    }

    public get usedInsights() {
      return this.inEvals
        ? this.allInsights.filter((insight: Insight) => insight.spotlight === this.formula.id)
        : this.allInsights.filter((insight: Insight) => insight.rules?.includes(this.formula.id))
    }

    public get usedInsightIds() {
      return this.usedInsights.map((insight: Insight) => insight.id)
    }

    public get insightProjects() {
      return this.projectsStore.projects.filter((p) => p.sections.content)
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get formulaCategories() {
      return this.inRules ? insightFormulaCategories : spotlightFormulaCategories
    }

    @Emit('formulaInsights')
    public emitFormulaInsights(insights: any[]) {
      return insights
    }

    @Emit('updateSaveChanges')
    public emitUpdateSaveChanges(success: boolean) {
      return success
    }

    @Watch('rule', { immediate: true })
    protected async onRuleChanged() {
      this.toggleAllExact = false
      this.toggleAllAlmost = false

      this.checkConditionMatches()
    }

    @Watch('usedInsights', { immediate: true })
    protected async onUsedInsightsChanged() {
      this.emitFormulaInsights(this.usedInsights)
    }

    @Debounce(1000)
    public updateMatches() {
      this.checkConditionMatches()
    }

    public editInsight(insightId: string) {
      this.insightsStore.searchResults = this.usedInsights

      this.$router.push('/messaging/' + insightId)
    }

    public updateCategory(colorName: string) {
      this.formula.color = colorName

      this.formula.category = this.formulaCategories[colorName].value
    }

    public humanReadableTags(insight: Insight) {
      const tags = this.insightsStore.featureTags

      return tags
        .filter((tag: any) => insight!.tags.includes(tag.value))
        .map((tag: any) => tag.text)
        .join(' ')
    }

    public async copyToClipboard(id: string) {
      this.copiedTexts.push(id)

      navigator.clipboard.writeText(id).then(
        () => {
          setTimeout(() => {
            this.copiedTexts = []
          }, 1000)
        },
        () => {
          console.warn('Failed to copy to the clipboard')
        },
      )
    }

    private async checkConditionMatches() {
      const matches: string[] = []

      this.ruleExactMatches = []
      this.ruleAlmostMatches = []

      const conditions = [this.formula.condition]

      const result = await simplifyStatement(this.formula.condition)

      if (result.success && result.value !== this.formula.condition) {
        conditions.push(result.value)
      }

      conditions.forEach((condition) => {
        const ruleParts = condition.split('&&').map((rule: string) => rule.trim())

        this.allInsights.forEach((insight) => {
          if (insight.rule && !this.usedInsights.includes(insight)) {
            const insightRuleParts = insight.rule.split('&&').map((rule: string) => rule.trim())

            if (
              !matches.includes(insight.id) &&
              insight.rule.includes(condition.trim().replace('\n', '')) &&
              intersection(ruleParts, insightRuleParts).length === ruleParts.length
            ) {
              matches.push(insight.id)

              this.ruleExactMatches.push({
                id: insight.id,
                tags: insight.tags,
                state: insight.state,
                rule: insight.rule,
                rules: insight.rules,
                condition: condition,
                newRule: insightRuleParts.filter((r: any) => !ruleParts.includes(r)).join(' && '),
              })
            }
          }
        })

        this.allInsights.forEach((insight) => {
          if (insight.rule && !this.usedInsights.includes(insight)) {
            const insightRuleParts = insight.rule.split('&&').map((rule: string) => rule.trim())

            if (
              !matches.includes(insight.id) &&
              !insight.rule.includes(condition.trim().replace('\n', '')) &&
              intersection(ruleParts, insightRuleParts).length === ruleParts.length
            ) {
              matches.push(insight.id)

              this.ruleAlmostMatches.push({
                id: insight.id,
                tags: insight.tags,
                state: insight.state,
                rule: insight.rule,
                rules: insight.rules,
                condition: condition,
                newRule: insightRuleParts.filter((r: any) => !ruleParts.includes(r)).join(' && '),
              })
            }
          }
        })
      })
    }
  }

  export default toNative(CreateFormula)
</script>
