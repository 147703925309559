<template>
  <div>
    <div v-for="(group, i) of expressions" :key="i">
      <div v-for="(expression, j) of group.oneOf.and.expressions" :key="i + '-' + j">
        <v-row align="center">
          <v-col>
            <v-autocomplete
              hide-details
              return-object
              label="Predicate"
              placeholder="Select predicate"
              :disabled="disabled"
              :items="listCriteriaPredicates()"
              :model-value="getPredicateObject(expression) || null"
              @update:model-value="updatePredicate(expression, $event)"
            >
              <template #append>
                <v-btn
                  icon="mdi-trash-can-outline"
                  class="ml-n2"
                  size="large"
                  variant="tonal"
                  color="grey"
                  :disabled="disabled"
                  @click="deletePredicate(expressions, i, j)"
                />
              </template>

              <template #selection="{ item }">
                <span :innerHTML="item.title.replace(/\bnot\b/, '<span class=\'text-orange\'>not</span>')" />
              </template>

              <template #prepend-inner>
                <v-tooltip
                  location="top start"
                  :text="invertedPredicates ? 'Switch to normal predicates' : 'Switch to inversed predicates'"
                >
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      class="ml-n2"
                      icon="mdi-swap-horizontal"
                      @mousedown="togglePredicatedInverting(expression, $event)"
                    />
                  </template>
                </v-tooltip>
              </template>
            </v-autocomplete>

            <v-alert
              v-if="getPredicateObject(expression)?.value === 'client-primaryRing-hardwareType'"
              closable
              class="mt-2"
              type="warning"
            >
              Using the hardware type predicate is not recommended. Please consider using the ring capability predicate
              for future-proof rollouts. If you need to exclude a specific hardware for business reasons, using the ring
              hardware type predicate with `NOT` is acceptable.
            </v-alert>
          </v-col>
        </v-row>

        <div class="pl-10">
          <template v-if="getPredicateData(expression)?.$case && getPredicateType(expression) === 'capabilityMatch'">
            <v-row class="mt-n4">
              <v-col cols="12">
                <v-select
                  hide-details
                  label="Capability"
                  density="compact"
                  :disabled="disabled"
                  :items="criteriaEnumTypes['ringCapability']"
                  :model-value="getPredicateValue(expression).capability"
                  @update:model-value="getPredicateValue(expression).capability = $event"
                />
              </v-col>
            </v-row>

            <v-row class="mt-n4">
              <v-col>
                <v-select
                  hide-details
                  return-object
                  label="Version"
                  density="compact"
                  :disabled="disabled"
                  :items="criteriaOperators['integerMatch']"
                  :model-value="
                    criteriaOperators['integerMatch'].find(
                      (i: any) => i.value === getPredicateValue(expression).version.oneOf.$case,
                    )
                  "
                  @update:model-value="
                    getPredicateValue(expression).version = { oneOf: { $case: $event.value, [$event.value]: '1' } }
                  "
                />
              </v-col>

              <v-col v-if="getPredicateValue(expression).version.oneOf.$case !== 'isPresent'" class="ml-n4">
                <v-text-field
                  hide-details
                  type="number"
                  label="Value"
                  density="compact"
                  :disabled="disabled"
                  :model-value="
                    getPredicateValue(expression).version.oneOf[getPredicateValue(expression).version.oneOf.$case]
                  "
                  @update:model-value="
                    getPredicateValue(expression).version.oneOf[getPredicateValue(expression).version.oneOf.$case] =
                      $event || '1'
                  "
                />
              </v-col>
            </v-row>
          </template>

          <v-row
            v-else-if="getPredicateData(expression)?.$case && getPredicateType(expression) !== 'booleanMatch'"
            class="mt-n4 mb-n3"
          >
            <template v-if="!criteriaEnumTypes[getPredicateType(expression)]">
              <v-col cols="12">
                <v-select
                  hide-details
                  return-object
                  label="Operator"
                  density="compact"
                  :disabled="disabled"
                  :append-icon="getPredicateField(expression) !== 'equalsAnyOf' ? '' : 'mdi-plus-thick'"
                  :items="criteriaOperators[getPredicateType(expression)]"
                  :model-value="getPredicateOperator(expression)"
                  @click:append="setPredicateValue(expression, '', getPredicateOptions(expression).length)"
                  @update:model-value="setPredicateField(expression, $event)"
                />
              </v-col>

              <v-col v-if="getPredicateField(expression) !== 'isPresent'" cols="12" class="mt-n4">
                <template v-if="getPredicateType(expression).startsWith('stringMatch')">
                  <div v-for="(_item, k) of getPredicateOptions(expression)" :key="k">
                    <template
                      v-if="
                        getPredicateObject(expression)?.value?.startsWith('user-countryOfResidence') ||
                        getPredicateObject(expression)?.value?.startsWith('client-locationCountryCode')
                      "
                    >
                      <v-text-field
                        hide-details
                        class="mb-1"
                        label="Value"
                        density="compact"
                        placeholder="2 letter ISO country code"
                        :disabled="disabled"
                        :model-value="getPredicateValue(expression, k)"
                        :autofocus="getPredicateField(expression) === 'equalsAnyOf'"
                        :append-icon="
                          getPredicateField(expression) === 'equalsAnyOf' && getPredicateOptions(expression).length > 1
                            ? 'mdi-close'
                            : ''
                        "
                        @click:append="getPredicateOptions(expression).splice(k, 1)"
                        @update:model-value="setPredicateValue(expression, $event.toUpperCase(), k)"
                        @keyup.enter="
                          getPredicateField(expression) === 'equalsAnyOf' &&
                          setPredicateValue(expression, '', getPredicateOptions(expression).length)
                        "
                      />
                    </template>
                    <template v-else>
                      <v-text-field
                        hide-details
                        class="mb-1"
                        label="Value"
                        density="compact"
                        :disabled="disabled"
                        :model-value="getPredicateValue(expression, k)"
                        :autofocus="getPredicateField(expression) === 'equalsAnyOf'"
                        :append-icon="
                          getPredicateField(expression) === 'equalsAnyOf' && getPredicateOptions(expression).length > 1
                            ? 'mdi-close'
                            : ''
                        "
                        @click:append="getPredicateOptions(expression).splice(k, 1)"
                        @update:model-value="setPredicateValue(expression, $event, k)"
                        @keyup.enter="
                          getPredicateField(expression) === 'equalsAnyOf' &&
                          setPredicateValue(expression, '', getPredicateOptions(expression).length)
                        "
                      />
                    </template>
                  </div>
                </template>

                <template v-else-if="getPredicateType(expression).startsWith('integerMatch')">
                  <v-text-field
                    hide-details
                    type="number"
                    label="Value"
                    density="compact"
                    :disabled="disabled"
                    :model-value="getPredicateValue(expression)"
                    @update:model-value="setPredicateValue(expression, $event || '0')"
                  />
                </template>

                <template v-else-if="getPredicateType(expression).startsWith('versionMatch')">
                  <div class="d-flex flex-row">
                    <v-text-field
                      hide-details
                      class="mr-2"
                      density="compact"
                      label="Major"
                      type="number"
                      :disabled="disabled"
                      :model-value="getPredicateValue(expression).major"
                      @update:model-value="
                        setPredicateValue(expression, {
                          major: parseInt($event || '0'),
                        })
                      "
                    />
                    <v-text-field
                      hide-details
                      class="mr-2"
                      label="Minor"
                      density="compact"
                      type="number"
                      :disabled="disabled"
                      :model-value="getPredicateValue(expression).minor"
                      @update:model-value="
                        setPredicateValue(expression, {
                          minor: parseInt($event || '0'),
                        })
                      "
                    />
                    <v-text-field
                      hide-details
                      label="Patch"
                      density="compact"
                      type="number"
                      :disabled="disabled"
                      :model-value="getPredicateValue(expression).patch"
                      @update:model-value="
                        setPredicateValue(expression, {
                          patch: parseInt($event || '0'),
                        })
                      "
                    />
                  </div>
                </template>

                <template v-else-if="getPredicateType(expression) === 'timestampMatch'">
                  <v-menu
                    v-if="getPredicateField(expression) === 'after' || getPredicateField(expression) === 'before'"
                    v-model="menuOpen"
                    left
                    offset-y
                    min-width="auto"
                    max-width="300px"
                    :disabled="disabled"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        readonly
                        hide-details
                        persistent-hint
                        label="Date"
                        class="ml-4"
                        density="compact"
                        prepend-icon="mdi-calendar"
                        :disabled="disabled"
                        :model-value="
                          getPredicateValue(expression)
                            ? $dayjs(getPredicateValue(expression)).format('DD MMM YYYY')
                            : ''
                        "
                        v-bind="props"
                        @update:model-value="setPredicateValue(expression, $dayjs($event).toISOString())"
                      />
                    </template>
                    <v-date-picker
                      no-title
                      locale-first-day-of-year="1"
                      :disabled="disabled"
                      :model-value="$dayjs(getPredicateValue(expression)).toDate()"
                      @update:model-value="
                        (setPredicateValue(expression, $dayjs($event).toISOString()), (menuOpen = false))
                      "
                    />
                  </v-menu>
                  <v-text-field
                    v-else
                    hide-details
                    type="number"
                    density="compact"
                    label="Duration (days)"
                    placeholder="Number of days"
                    :disabled="disabled"
                    :model-value="
                      getPredicateValue(expression)
                        ? $dayjs.duration({ seconds: getPredicateValue(expression).seconds }).asDays()
                        : ''
                    "
                    @update:model-value="
                      setPredicateValue(expression, {
                        nanos: 0,
                        seconds: $dayjs
                          .duration({ days: parseInt($event) })
                          .asSeconds()
                          .toString(),
                      })
                    "
                  />
                </template>
              </v-col>
            </template>

            <template v-else>
              <v-col>
                <v-select
                  hide-details
                  label="Value"
                  density="compact"
                  :disabled="disabled"
                  :items="criteriaEnumTypes[getPredicateType(expression)]"
                  :model-value="getPredicateValue(expression)"
                  @update:model-value="setPredicateValue(expression, $event)"
                />
              </v-col>
            </template>
          </v-row>
        </div>

        <div
          v-if="j !== group.oneOf.and.expressions.length - 1"
          class="my-3"
          style="position: relative; text-align: center"
        >
          <span
            class="px-4"
            style="
              color: rgb(var(--v-theme-on-surface));
              background: rgb(var(--v-theme-surface));
              transform: translateX(-50%);
            "
          >
            <v-chip size="small">AND</v-chip>
          </span>
        </div>
      </div>

      <v-row>
        <v-col class="text-right">
          <v-btn
            text="Add OR"
            color="primary"
            size="small"
            class="mr-2"
            :disabled="disabled"
            @click="appendPredicate($event.target, expressions, i + 1)"
          />

          <v-btn
            text="Add AND"
            color="primary"
            size="small"
            :disabled="disabled"
            @click="appendPredicate($event.target, group.oneOf.and.expressions)"
          />
        </v-col>
      </v-row>

      <div v-if="i !== expressions.length - 1" class="my-8" style="position: relative; text-align: center">
        <v-divider style="border-color: black" />
        <span
          class="px-4"
          style="
            position: absolute;
            top: -20px;
            color: rgb(var(--v-theme-on-surface));
            background: rgb(var(--v-theme-surface));
            transform: translateX(-50%);
          "
        >
          <v-chip size="large">OR</v-chip>
        </span>
      </div>
    </div>

    <v-row id="add-predicate" class="mt-2">
      <v-col class="text-right">
        <v-btn
          v-if="!expressions.length"
          color="primary"
          size="small"
          :text="action"
          :disabled="disabled"
          @click="appendPredicate('#add-predicate', expressions, 0)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { useGoTo } from 'vuetify'

  import { Component, Emit, Prop, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Expression } from '@jouzen/feature-mgmt-api/criteria'

  import {
    criteriaEnumTypes,
    criteriaOperators,
    criteriaPredicates,
    segmentConfigTypes,
  } from '#views/segments/constants'

  import { getPredicateData } from '#views/segments/utilities'

  import { ReleasesStore } from '#stores'

  @Component({})
  class CriteriaPreds extends Vue {
    @Prop() public action!: string

    @Prop() public disabled!: boolean

    @Prop() public expressions!: any[]

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public menuOpen = false

    public invertedPredicates = false

    public readonly getPredicateData = getPredicateData

    public readonly criteriaOperators = criteriaOperators
    public readonly criteriaEnumTypes = criteriaEnumTypes
    public readonly segmentConfigTypes = segmentConfigTypes

    private latestVersions: any = { ios: {}, android: {} }

    protected readonly releasesStore = new ReleasesStore()

    @Emit('change')
    public emitChange() {
      return this.expressions
    }

    @Emit('validate')
    public emitValidate() {
      return this.expressions
    }

    @Watch('expressions', { deep: true, immediate: true })
    protected expressionChanged() {
      this.emitValidate()
    }

    public async mounted() {
      this.latestVersions.ios = await this.releasesStore.fetchLatestVersion('ios')
      this.latestVersions.android = await this.releasesStore.fetchLatestVersion('android')
    }

    public appendPredicate(el: any, expressions: any[], index?: number) {
      if (index !== undefined) {
        const predicate = {
          oneOf: {
            $case: 'and',
            and: {
              expressions: [],
            },
          },
        }

        expressions.splice(index, 0, predicate)

        expressions = predicate.oneOf.and.expressions
      }

      expressions.push({
        oneOf: {
          $case: 'predicate',
          predicate: {
            oneOf: { $case: '' },
          },
        },
      })

      window.setTimeout(() => this.goTo(el, { container: '#segment-panel' }), 10)

      this.emitChange()
    }

    public deletePredicate(expressions: any, group: number, index: number) {
      expressions[group].oneOf.and.expressions.splice(index, 1)

      if (!expressions[group].oneOf.and.expressions.length) {
        expressions.splice(group, 1)
      }

      this.emitChange()
    }

    public updatePredicate(expression: Expression, selected: any) {
      this.updateExpressionType(expression)

      let predicate: any = this.getPredicateData(expression)

      if (selected) {
        const values = selected.value.split('-')

        for (let i = 0; i < values.length; i++) {
          if (i < values.length - 1) {
            predicate.$case = values[i]

            predicate[values[i]] = {
              oneOf: {},
            }

            predicate = predicate[values[i]].oneOf
          } else {
            predicate.$case = values[i]

            if (selected.type === 'booleanMatch') {
              predicate[values[i]] = true
            } else if (selected.type === 'capabilityMatch') {
              predicate[values[i]] = {
                capability: criteriaOperators[selected.type][0].default,
                version: {
                  oneOf: {
                    $case: 'isPresent',
                    isPresent: true,
                  },
                },
              }
            } else if (criteriaOperators[selected.type]) {
              predicate[values[i]] = {
                oneOf: {},
              }

              this.setPredicateField(expression, criteriaOperators[selected.type][0])
            } else {
              predicate[values[i]] = criteriaEnumTypes[selected.type][0].value
            }
          }
        }
      }
    }

    public getPredicateType(expression: Expression) {
      let predicate: any = this.getPredicateData(expression)

      let name = predicate.$case

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf

        name = name + '-' + predicate.$case

        const type = this.listCriteriaPredicates(expression).find((p: any) => p.value === name)?.type

        if (type) {
          return type
        }
      }

      return 'unknown'
    }

    public getPredicateField(expression: Expression) {
      let predicate: any = this.getPredicateData(expression)

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf
      }

      return predicate.$case
    }

    public setPredicateField(expression: Expression, field: any) {
      let predicate: any = this.getPredicateData(expression)

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf
      }

      delete predicate[predicate.$case]

      predicate.$case = field.value

      if (field.default != null) {
        if (field.default !== 'version') {
          if (field.value !== 'equalsAnyOf') {
            predicate[field.value] = field.default
          } else {
            predicate[field.value] = { strings: [field.default] }
          }
        } else {
          predicate[field.value] = this.latestVersions || { marjor: 5, minor: 0, patch: 0 }
        }
      } else if (field.datetime != null) {
        predicate[field.value] = this.$dayjs(field.datetime).toISOString()
      } else if (field.duration != null) {
        predicate[field.value] = { nanos: 0, seconds: this.$dayjs.duration(field.duration).asSeconds().toString() }
      }
    }

    public getPredicateObject(expression: Expression) {
      const inverted = expression.oneOf?.$case === 'not'

      let predicate: any = this.getPredicateData(expression)

      if (predicate) {
        let name = predicate.$case

        while (predicate[predicate?.$case]?.oneOf) {
          predicate = predicate[predicate.$case].oneOf

          name = name + '-' + predicate.$case

          const obj = this.listCriteriaPredicates(expression).find((p: any) => p.value === name)

          if (obj) {
            return { ...obj, value: inverted !== this.invertedPredicates ? '' : obj.value }
          }
        }
      }

      return ''
    }

    public getPredicateOperator(expression: Expression) {
      const value = this.getPredicateField(expression)

      const items = criteriaOperators[this.getPredicateType(expression)]

      return items.find((i: any) => i.value === value)
    }

    public getPredicateOptions(expression: Expression) {
      let predicate: any = this.getPredicateData(expression)

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf
      }

      return predicate[predicate.$case].strings || [predicate[predicate.$case]]
    }

    public getPredicateValue(expression: Expression, index?: number) {
      let predicate: any = this.getPredicateData(expression)

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf
      }

      if (this.getPredicateField(expression) !== 'equalsAnyOf') {
        return predicate[predicate.$case]
      } else {
        return predicate[predicate.$case].strings[index || 0]
      }
    }

    public setPredicateValue(expression: Expression, value: any, index?: number) {
      let predicate: any = this.getPredicateData(expression)

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf
      }

      if (this.getPredicateField(expression) === 'equalsAnyOf') {
        predicate[predicate.$case].strings[index || 0] = value
      } else if (typeof value == 'object') {
        predicate[predicate.$case] = { ...predicate[predicate.$case], ...value }
      } else {
        predicate[predicate.$case] = value
      }
    }

    public listCriteriaPredicates(expression?: any) {
      const inverted = !expression ? this.invertedPredicates : expression.oneOf.$case === 'not'

      return criteriaPredicates[inverted ? 'inverted' : 'default']
    }

    public togglePredicatedInverting(expression: any, event: any) {
      this.invertedPredicates = !this.invertedPredicates

      if (
        expression?.oneOf?.predicate?.oneOf?.$case ||
        expression?.oneOf?.not?.expression.oneOf?.predicate?.oneOf?.$case
      ) {
        event.stopPropagation()

        this.updateExpressionType(expression)
      }
    }

    private updateExpressionType(expression: any) {
      if (!this.invertedPredicates && expression.oneOf.$case === 'not') {
        expression.oneOf = {
          $case: 'predicate',
          predicate: {
            oneOf: this.getPredicateData(expression),
          },
        }
      } else if (this.invertedPredicates && expression.oneOf.$case !== 'not') {
        expression.oneOf = {
          $case: 'not',
          not: {
            expression: {
              oneOf: {
                $case: 'predicate',
                predicate: {
                  oneOf: this.getPredicateData(expression),
                },
              },
            },
          },
        }
      }
    }
  }

  export default toNative(CriteriaPreds)
</script>

<style lang="scss" scoped>
  :deep(.v-list-subheader) {
    color: rgb(var(--v-theme-primary));
    text-transform: uppercase;
  }

  :deep(.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon) {
    transform: none;
  }
</style>
