<template>
  <v-dialog v-model="isOpen" width="800px">
    <v-card>
      <v-card-title>Send your insight to review</v-card-title>

      <v-card-text>
        <p>
          Sending to review means that content editor will check your insight and give feedback to you if something is
          not correct.
        </p>
        <p>
          Please describe the idea of your insight in the variant="text" box below so that content editors can decide if
          it can be added and what kind of rule logic it needs.
        </p>
        <p>
          Once the insight is approved, it'll be moved to actual production insights with the state 'draft' and content
          editors will finalize it.
        </p>

        <v-textarea v-model="reviewNote" label="Descripe your insight here" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" @click="isOpen = false" />

        <v-btn text="Send" color="primary" :loading="isLoading" :disabled="!reviewNote" @click="sendToReview()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { doc, getFirestore, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'

  import { AppStore } from '#stores'

  import { prefixedId } from '#utilities'

  @Component({})
  class SendToReview extends Vue {
    public isOpen = false
    public isLoading = false

    public reviewNote = ''

    private insightId = ''
    private insightPath = ''

    private readonly appStore = new AppStore()

    public open(insightId: string, insightPath: string) {
      this.insightId = insightId
      this.insightPath = insightPath

      this.isOpen = true
    }

    public async sendToReview() {
      this.isLoading = true

      const data = {
        state: 'review',
        author: this.appStore.author,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
      }

      await setDoc(doc(getFirestore(), `users/${this.appStore.user?.uid}/reviews/${this.insightId}`), {
        [prefixedId('review')]: {
          author: this.appStore.author,
          message: this.reviewNote,
          date: serverTimestamp(),
        },
      })

      await updateDoc(doc(getFirestore(), this.insightPath), data)

      this.reviewNote = ''

      this.isLoading = false
      this.isOpen = false
    }
  }

  export default toNative(SendToReview)
</script>
