<template>
  <v-dialog v-if="rules && editedInsight && isOpen" v-model="isOpen" width="1200" min-height="800" max-height="800">
    <v-card>
      <v-card-title>
        {{ !!title ? 'Insight rules / condition' : 'Edit rules / condition' }}
      </v-card-title>

      <v-card-subtitle v-if="title" class="mt-n4 mb-4">{{ title }}</v-card-subtitle>

      <v-card-text class="d-flex flex-column flex-shrink-1">
        <v-alert type="info" class="mb-6 flex-grow-0 flex-shrink-0">
          To release the changes saved in this dialog you need to rollout the
          <b>"Condition changes"</b>
          project!
        </v-alert>

        <div class="d-flex flex-row flex-grow-1 flex-shrink-1" style="min-height: 0">
          <div
            class="pr-8 flex-grow-1 flex-shrink-1"
            style="overflow: auto; min-height: 0; min-width: 400px; max-width: 400px"
          >
            <template v-if="editRule">
              <template v-for="(rule, index) in editedInsight.rules" :key="rule">
                <div :class="`rounded-lg d-flex pa-4 pb-2 bg-${findRule(rule)?.color}-lighten-3`">
                  <p>{{ findRule(rule)?.title }}</p>

                  <v-spacer />

                  <v-icon
                    icon="mdi-trash-can-outline"
                    style="cursor: pointer; font-size: 18px"
                    class="align-self-center ml-2 mt-n2"
                    @click="removeRule(rule)"
                  />
                </div>

                <p
                  v-if="index < editedInsight.rules.length - 1 || editedInsight.rule"
                  :key="`and${rule}`"
                  class="my-2 text-center"
                >
                  AND
                </p>
              </template>

              <v-chip
                v-if="editedInsight.rule"
                label
                text="Insight specific rule"
                class="mb-2"
                style="min-width: 100%"
              />
            </template>

            <template v-else>
              <v-text-field v-model="ruleFilter" autofocus persistent-placeholder placeholder="Filter rules..." />

              <div
                v-for="rule in rules.filter(
                  (r) =>
                    !editedInsight?.rules.includes(r.id) &&
                    (!ruleFilter || r.title.toLowerCase().includes(ruleFilter.toLowerCase())),
                )"
                :key="rule.id"
                style="cursor: pointer"
                :class="'rounded-lg mb-2 d-flex pa-2 bg-' + rule.color + '-lighten-3'"
                @click="appendRule(rule)"
              >
                <p class="text-truncate">{{ rule.title }}</p>
              </div>
            </template>
          </div>

          <div cols="12" md="8" style="border-left: 1px solid lightgrey" class="pl-8 flex-grow-1">
            <StatementPreview
              readonly
              label="Condition preview"
              :rows="14"
              :value="getCondition(false)"
              :highlight="sortedList.map((r) => r.condition)"
              :highlight-text="getCondition(true)"
              :highlight-color="sortedList.map((r) => r.color + '-lighten-5')"
              :highlight-tooltip="sortedList.map((r) => r.title)"
            />

            <Statement v-model="editedInsight.rule" class="mt-4" simplify label="Insight specific rule" :rows="3" />
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <div v-if="!title" class="col-4">
          <v-btn v-if="editRule" color="primary" class="" text="Add existing rule" @click="editRule = false" />

          <v-btn v-else color="primary" text="Cancel rule adding" @click="editRule = true" />
        </div>

        <v-spacer />

        <v-btn text="Close" color="primary" class="mr-4" @click="isOpen = false" />

        <v-btn text="Save" color="primary" :disabled="!hasChanges" @click="save()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { cloneDeep, isEqual, orderBy } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { FormulasStore, InsightsStore } from '#stores'

  import { Formula, Insight } from '#types'

  @Component({})
  class EditRuleDialog extends Vue {
    @Prop() public rules!: Formula[]
    @Prop() public insight!: Insight

    public title = ''

    public isOpen = false
    public editRule = true

    public ruleFilter = ''

    public sortedList: Formula[] = []

    public editedInsight: Insight | null = null

    private readonly formulasStore = new FormulasStore()
    private readonly insightsStore = new InsightsStore()

    public hasChanges() {
      return !isEqual(this.insight, this.editedInsight)
    }

    @Watch('rules', { immediate: true })
    protected onRulesChanged() {
      if (this.rules && this.rules.length) {
        this.sortedList = orderBy(this.rules, [(o) => o.condition.length], 'desc')
      }
    }

    public open(insightId?: string) {
      this.editRule = true

      this.editedInsight = cloneDeep(this.insight)

      this.title = insightId || ''

      this.isOpen = true
    }

    public async save() {
      if (this.editedInsight) {
        await this.insightsStore.updateCondition({
          id: this.editedInsight.id,
          rule: this.editedInsight.rule,
          rules: this.editedInsight.rules,
        })
      }

      this.isOpen = false
    }

    public findRule(id: string) {
      return this.rules?.find((r) => r.id === id)
    }

    public appendRule(rule: Formula | null) {
      this.editedInsight!.rules!.push(rule!.id)

      this.editRule = true
    }

    public removeRule(rule: string) {
      this.editedInsight!.rules = this.editedInsight?.rules?.filter((r) => r !== rule) || []
    }

    public getCondition(split: boolean) {
      const rules = [
        this.editedInsight?.rules?.map((r) => this.findRule(r)?.condition.trim()).join(' && '),
        this.editedInsight?.rules?.length && this.editedInsight?.rule ? ' && ' : '',
        this.editedInsight?.rule || '',
      ]

      return split ? rules.filter(Boolean) : rules.join('')
    }
  }

  export default toNative(EditRuleDialog)
</script>
