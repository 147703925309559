<template>
  <v-dialog v-model="open" persistent width="1000" @click:outside="!hasChanges && close()">
    <v-card>
      <v-row>
        <v-col>
          <v-card-title>{{ action }} Project</v-card-title>
        </v-col>

        <v-col v-if="hasProjectStateEditRights" class="d-flex align-center me-6 justify-end">
          <v-menu v-model="menu" transition="slide-y-transition">
            <template #activator="{ props }">
              <v-chip
                v-bind="props"
                :style="{
                  color: getStateTextColor(projectState),
                  background: getStateColor(projectState),
                  fontSize: '12px',
                  minWidth: '110px',
                }"
                :text="projectState.toUpperCase() || 'Select state'"
              />
            </template>

            <v-list class="mt-2">
              <v-list-item
                v-for="state in projectStates.filter((state) => state.title !== projectState)"
                :key="state.title"
                :title="state.title"
                @click="[(edited.state = state.title), (menu = false)]"
              />
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-card-text>
        <v-row class="mt-n4">
          <v-col>
            <v-text-field
              v-model.trim="edited.name"
              autofocus
              label="Project name *"
              :readonly="isSystemProject || !isProjectAdminOrOwner"
              :rules="[(v: string) => !!v || 'This field is required']"
              @blur="formatProjectName()"
            />
          </v-col>

          <v-col>
            <v-combobox
              v-model.trim="edited.team"
              label="Project owner team name *"
              hide-selected
              :items="teamNames"
              :rules="[(v: string) => !!v || 'This field is required']"
              @blur="formatTeamName()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col>
            <v-textarea
              v-model.trim="edited.info"
              cols="2"
              label="Project description"
              placeholder="Short description why the project exists and what is its purpose..."
              :readonly="!isProjectAdminOrOwner"
            />
          </v-col>
        </v-row>

        <v-row v-if="edited.sections.content && projectState === 'Blocked'" class="mt-n6">
          <v-col>
            <v-textarea
              v-model="edited.status"
              cols="2"
              label="Status *"
              placeholder="Explain why status is blocked or add a link to related issue"
              :focused="projectState === 'Blocked'"
              :readonly="!hasProjectStateEditRights"
              :rules="[(v: string) => !!v || 'This field is required']"
            />
          </v-col>
        </v-row>

        <div class="text-overline my-n3">Please select all use cases for this project.</div>

        <v-row>
          <v-col>
            <v-checkbox
              v-model="edited.sections.content"
              hide-details
              label="App content creation (Contents, Insights, Formulas)"
              :readonly="!isProjectAdminOrOwner"
            />

            <v-checkbox
              v-model="edited.sections.features"
              class="mt-n2 mb-4"
              label="App features and segments (Flags, Rollouts, Experiments)"
              :readonly="!isProjectAdminOrOwner"
            />
          </v-col>

          <v-col>
            <v-alert type="info" class="mt-1 mb-2">
              Project ID / prefix

              <template v-if="edited.sections.features">
                ,

                <a
                  target="_blank"
                  href="https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management#%5BinlineExtension%5DWhich-project-to-use"
                >
                  see docs for more information!
                </a>
              </template>
              <template v-else>is only needed for app features</template>
            </v-alert>

            <v-combobox
              v-model="edited.key"
              density="compact"
              hint="Max 20 characters"
              placeholder="Select existing or input new project ID / prefix *"
              :readonly="!!original.key || !isProjectAdminOrOwner"
              :disabled="!edited.sections.features"
              :items="featureProjects"
              @blur="formatProjectKey()"
            >
              <template #prepend-inner>
                <v-icon class="mx-2">mdi-identifier</v-icon>
              </template>
            </v-combobox>
          </v-col>
        </v-row>

        <div class="text-overline mt-2 mb-4">Please enter the emails for owner, admins and members.</div>

        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="edited.owner"
              v-lowercase
              label="Project owner *"
              placeholder="Email of the project owner"
              :readonly="!isProjectAdminOrOwner"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-lowercase
              class="mt-n4"
              label="Project admins"
              placeholder="Optional, can edit the project and editing rights"
              :readonly="!isProjectAdminOrOwner"
              :model-value="(edited.admins || []).join(', ')"
              @update:model-value="edited.admins = $event.split(/[\s,]+/).map((e: string) => e.trim())"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              v-lowercase
              cols="2"
              class="mt-n4"
              label="Users with editing access"
              placeholder="Optional, owner and admin can always edit the content"
              :readonly="!isProjectAdminOrOwner"
              :model-value="(edited.editors || []).join(', ')"
              @update:model-value="edited.editors = $event.split(/[\s,]+/).map((e: string) => e.trim())"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="action === 'Edit' || action === 'View'"
          text="Duplicate"
          color="primary"
          @click="duplicateProject()"
        />

        <v-spacer />

        <v-btn v-if="!hasProjectStateEditRights" text="Close" color="primary" @click="close()" />

        <template v-else>
          <v-btn :text="hasChanges ? 'Cancel' : 'Close'" color="primary" @click="close()" />

          <v-tooltip
            text="Status is required"
            :disabled="statusAvailable || edited.state !== 'Blocked'"
            location="bottom"
          >
            <template #activator="{ props }">
              <div v-bind="props">
                <v-btn
                  color="primary"
                  :text="action === 'Create' || action === 'Duplicate' ? action : 'Save'"
                  :disabled="
                    !edited.name ||
                    !edited.team ||
                    !hasChanges ||
                    (edited.sections.features && !edited.key) ||
                    (edited.state === 'Blocked' && !statusAvailable)
                  "
                  @click="saveProject()"
                />
              </div>
            </template>
          </v-tooltip>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { v4 as uuid } from 'uuid'

  import { cloneDeep, cloneDeepWith, countBy, isEqual, omit, snakeCase, startCase, union, uniq } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { projectStates } from '#views/projects/constants'

  import { AppStore, ProjectsStore } from '#stores'

  import { ProjectStateType, WaltariProject } from '#types'

  @Component({})
  class ProjectEditor extends Vue {
    @Prop() public open!: boolean
    @Prop() public action!: string
    @Prop() public project!: WaltariProject

    @Emit('close')
    public close(projectId?: string) {
      return projectId
    }

    @Emit('newProject')
    public duplicateProject() {
      const omitkeys = ['createdAt', 'updatedAt', 'changesAt', 'releasedAt', 'state', 'status']

      this.edited = {
        ...cloneDeepWith(omit(this.project, omitkeys)),
        id: uuid(),
        name: '',
        owner: this.userEmail,
      }
    }

    public edited: any = null
    public original: any = null
    public menu = false
    public label: string = ''
    public projectStates = projectStates

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()

    public get userEmail() {
      return this.appStore.user?.email ? this.appStore.user?.email : ''
    }

    public get teamNames() {
      return uniq(this.projectsStore.projects.map((p: WaltariProject) => p.team)).sort((a, b) => a.localeCompare(b))
    }

    public get hasChanges() {
      return !isEqual(this.original, this.edited)
    }

    public get projectState() {
      return this.edited.state ?? ''
    }

    public get statusAvailable() {
      return this.edited?.status?.length > 0
    }

    public get featureProjects() {
      return uniq(this.projectsStore.projects.filter((p) => p.key).map((p) => ({ title: p.key, value: p.key })))
    }

    public get isSystemProject() {
      return (
        this.project.name === 'Condition changes' ||
        this.project.name === 'Spotlight changes' ||
        this.project.name === 'Translation changes'
      )
    }

    public get isOTAContentAdmin() {
      return this.appStore.isOTAContentAdmin
    }

    public get isProjectAdminOrOwner() {
      return (
        this.project.admins?.includes(this.userEmail) ||
        this.userEmail === this.project.owner ||
        this.appStore.isWaltariAdmin
      )
    }

    public get hasProjectStateEditRights() {
      return this.isProjectAdminOrOwner || this.isOTAContentAdmin || this.appStore.isWaltariAdmin
    }

    public getStateColor(selectedState: ProjectStateType) {
      return (projectStates.find((state) => state.title.toLowerCase() === selectedState) || {}).color
    }

    public getStateTextColor(selectedState: ProjectStateType) {
      return (projectStates.find((state) => state.title.toLowerCase() === selectedState) || {}).textColor
    }

    @Watch('edited.team')
    public teamChanged() {
      if (this.edited.team !== this.original.team && !this.original.admins?.length && !this.original.editors?.length) {
        this.updateProjectDetails()
      }
    }

    public created() {
      console.info('Editing project', this.project)

      this.edited = cloneDeep(this.project)

      this.original = cloneDeep(this.project)
    }

    public saveProject() {
      this.edited.admins = this.edited.admins?.filter((email: string) => email !== '') ?? []
      this.edited.editors = this.edited.editors?.filter((email: string) => email !== '') ?? []
      this.projectsStore.updateProject(this.edited)

      this.close(this.project.id)
    }

    public formatTeamName() {
      this.edited.team = startCase(this.edited.team)
    }

    public formatProjectKey() {
      if (this.edited.key) {
        this.edited.key = snakeCase(this.edited.key.toLowerCase()).slice(0, 20)
      }
    }

    public formatProjectName() {
      if (this.edited.name.length > 2) {
        this.edited.name = this.edited.name[0].toUpperCase() + this.edited.name.slice(1)
      }
    }

    public updateProjectDetails() {
      const teamName = this.edited.team

      const allProjects = this.projectsStore.projects

      // Filter projects that have the same team name
      const filteredProjects = allProjects.filter((p: WaltariProject) => p.team === teamName)

      // Extract and filter admin emails
      const adminEmails: string[] = []
      filteredProjects.forEach((project: WaltariProject) => {
        if (project.admins) {
          adminEmails.push(...project.admins)
        }
      })

      // Sort the most frequent admin emails
      const adminEmailCounts = countBy(adminEmails ?? [])
      const mostFrequentAdmins = Object.keys(adminEmailCounts).sort((a, b) => adminEmailCounts[b] - adminEmailCounts[a])

      // Return 5 most used admin emails after filtering out the project owner
      this.edited.admins =
        mostFrequentAdmins?.filter((email) => email !== this.edited.owner && email !== '').slice(0, 5) ?? []

      // Union editor emails and filter out the project owner and admins
      const editorEmails = filteredProjects.map((p: WaltariProject) => p.editors || [])
      this.edited.editors = union(...editorEmails).filter(
        (email: string) => !this.edited.admins.includes(email) && email !== this.edited.owner && email !== '',
      )
    }
  }

  export default toNative(ProjectEditor)
</script>
