<template>
  <v-navigation-drawer
    v-if="!!changedInsight"
    disable-resize-watcher
    style="right: 0"
    width="450"
    order="0"
    location="end"
    :model-value="isOpen"
  >
    <div class="d-flex flex-column fill-height">
      <v-row class="flex-grow-0 py-4 px-8">
        <v-col class="flex-grow-1">
          <div class="text-h5 font-weight-light">{{ startCase(title) }} metadata / settings</div>
          <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
            Helpful information about this {{ title }}
          </div>
        </v-col>

        <v-col class="flex-grow-0">
          <v-btn class="mt-n2 mr-n5" icon="mdi-close" variant="text" rounded="100%" @click="close()" />
        </v-col>
      </v-row>

      <v-divider />

      <div class="d-flex flex-column flex-grow-1 flex-shrink-1 pa-8">
        <v-form ref="insightForm" v-model="insightFormValidity" class="d-flex flex-column flex-grow-1 flex-shrink-1">
          <div class="text-overline">Metadata</div>

          <InsightConfig
            v-model="changedInsight"
            :store="store"
            :create-mode="false"
            :readonly="!isInsightsEditor"
            :category-items="insightCategories"
            :original="insight"
            @add-tag="addTag($event)"
            @category-changed="revalidateInsightForm()"
          />

          <div class="text-overline">Advanced</div>

          <v-checkbox
            v-if="!inWorkspace"
            v-model="changedInsight.silent"
            class="flex-grow-0"
            :readonly="!isInsightsEditor"
            label="Silence the notifications (legacy option)"
          />
        </v-form>
      </div>

      <v-row class="flex-grow-0 pa-4">
        <v-col class="text-center">
          <v-btn :disabled="!hasChanges" text="Save" color="primary" @click="saveChanges()" />
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { isEqual, startCase } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

  import {
    insightCategories,
    slideshowCategories,
    tipthemesCategories,
    workspaceCategories,
  } from '#views/insights/constants'

  import { AppStore, InsightsStore, SlideshowsStore, TipthemesStore } from '#stores'

  import { Insight } from '#types'

  @Component({})
  class InsightPanel extends Vue {
    @Prop() public open!: boolean
    @Prop() public title!: string
    @Prop() public store!: string

    @Prop() public insight!: any

    @Prop() public insightPath!: string

    @Emit('close')
    public close() {
      if (this.appStore.navDrawer === 'info') {
        this.appStore.closeNavDrawer()
      }

      return
    }

    declare public $refs: {
      insightForm: HTMLFormElement
    }

    public changedInsight: Insight | null = null

    public insightFormValidity = false

    public readonly startCase = startCase

    private readonly appStore = new AppStore()
    private readonly insightsStore = new InsightsStore()
    private readonly tipthemesStore = new TipthemesStore()
    private readonly slideshowsStore = new SlideshowsStore()

    public get isOpen() {
      return this.open || this.appStore.navDrawer === 'info'
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get insightCategories() {
      return this.insightPath.startsWith('/users/')
        ? workspaceCategories
        : this.insightPath.startsWith('/slideshows/')
          ? slideshowCategories
          : this.insightPath.startsWith('/tipthemes/')
            ? tipthemesCategories
            : insightCategories
    }

    public get errorsForSaveChanges() {
      if (!this.insight?.project) {
        return 'Project not set, changes can not be saved'
      } else if (!this.insightFormValidity) {
        return 'Errors in the insight form'
      } else {
        return null
      }
    }

    public get hasChanges() {
      return !isEqual(this.insight, this.changedInsight)
    }

    @Watch('insight', { immediate: true })
    protected onInsightChanged() {
      this.changedInsight = { ...this.insight }
    }

    public async saveChanges() {
      const author = this.appStore.author

      author.comment = 'Changed metainfo / settings'

      const updateData =
        this.store === 'slideshows'
          ? this.slideshowsStore.updateSlideshow
          : this.store === 'tipthemes'
            ? this.tipthemesStore.updateTiptheme
            : this.insightsStore.updateInsight

      await updateData({
        ...this.changedInsight,
        id: this.insight.id,
        author,
        updatedAt: serverTimestamp(),
        tags: this.changedInsight?.tags || [],
        silent: this.changedInsight?.silent || false,
        project: this.changedInsight?.project || '',
        category: this.changedInsight?.category || '',
        description: this.changedInsight?.description || '',
      })

      if (this.insight.spotlight !== this.changedInsight?.spotlight) {
        await this.insightsStore.updateSpotlight({
          id: this.insight.id,
          spotlight: this.changedInsight?.spotlight,
        })
      }

      this.close()
    }

    public async addTag(tag: string) {
      const key = tag.toLowerCase().replace(/ /g, '_')

      if (key && this.insight) {
        await setDoc(doc(getFirestore(), `tags/${key}`), {
          name: tag[0].toUpperCase() + tag.slice(1),
        })

        this.insight.tags.push(key)
      }
    }

    public revalidateInsightForm() {
      this.$refs.insightForm.validate()
    }
  }

  export default toNative(InsightPanel)
</script>
