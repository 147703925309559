<template>
  <div
    class="rounded grow message-container pa-1 bg-black"
    :style="`border: 4px solid ${messageColor(message).borderColor}`"
    @click="editMessage()"
  >
    <h3
      class="rounded text-capitalize"
      :style="`background-color: ${messageColor(message).backgroundColor}; color: ${messageColor(message).textColor}`"
    >
      {{ message.generated ? `${message.state} (AI)` : message.state }}
    </h3>

    <img v-if="snapshotURL" class="snapshot" :src="snapshotURL" />

    <div v-else class="d-flex flex-column placeholder-background">
      <div class="d-flex flex-row pa-1" @click.stop="">
        <v-icon v-if="activeTab === 'view'" color="grey" class="pr-1" icon="mdi-text-box-outline" />
        <v-icon v-if="activeTab === 'home'" color="grey" class="pr-1" icon="mdi-home" />
        <v-icon v-if="activeTab === 'media'" color="grey" class="pr-1" icon="mdi-folder-multiple-image" />
        <v-icon v-if="activeTab === 'links'" color="grey" class="pr-1" icon="mdi-link-variant" />
        <v-icon v-if="activeTab === 'notification'" color="grey" class="pr-1" icon="mdi-bell-outlin" />
        <i v-if="activeTab === 'view'" class="grey--text" title="View" />
        <i v-if="activeTab === 'home'" class="grey--text" title="Home" />
        <i v-if="activeTab === 'media'" class="grey--text" title="Media" />
        <i v-if="activeTab === 'links'" class="grey--text" title="Links" />
        <i v-if="activeTab === 'notification'" class="grey--text" title="Notifs" />

        <span
          v-if="(!!previousOrder || previousOrder === 0) && previousOrder !== message.order"
          class="pl-2 grey--text"
        >
          ({{ previousOrder }})
        </span>

        <v-spacer />

        <v-icon
          v-if="message.facets.view && activeTab !== 'view'"
          icon="mdi-text-box-outline"
          color="grey"
          class="pr-1"
          @click="activeTab = 'view'"
        />

        <v-icon
          v-if="message.facets.home && activeTab !== 'home'"
          icon="mdi-home"
          color="grey"
          class="pr-1"
          @click="activeTab = 'home'"
        />
        <v-icon
          v-if="message.media && activeTab !== 'media'"
          icon="mdi-folder-multiple-image"
          color="grey"
          class="pr-1"
          @click="activeTab = 'media'"
        />

        <v-icon
          v-if="message.links && activeTab !== 'links'"
          icon="mdi-link-variant"
          color="grey"
          class="pr-1"
          @click="activeTab = 'links'"
        />

        <v-icon
          v-if="message.facets.notification && activeTab !== 'notification'"
          icon="mdi-bell-outline"
          color="grey"
          class="pr-1"
          @click="activeTab = 'notification'"
        />

        <v-tooltip top>
          <template #activator="{ props }">
            <v-icon icon="mdi-content-duplicate" color="grey" v-bind="props" @click="duplicateMessage(message)" />
          </template>
          <span>Duplicate</span>
        </v-tooltip>
      </div>

      <div class="grow">
        <ViewFacetPreview
          v-if="message.facets.view && activeTab === 'view'"
          :message="message"
          :category="insight.category"
          :preview="true"
        />
        <HomeFacetPreview
          v-if="message.facets.home && activeTab === 'home'"
          :message="message"
          :preview="true"
          :category="insight.category"
        />
        <MediaObjectPreview
          v-if="message.media && activeTab === 'media'"
          :message="message"
          :preview="true"
          :category="insight.category"
        />
        <LinksObjectPreview
          v-if="message.links && activeTab === 'links'"
          :message="message"
          :preview="true"
          :category="insight.category"
        />
        <NotificationFacetPreview
          v-if="message.facets.notification && activeTab === 'notification'"
          :message="message"
          :preview="true"
          :category="insight.category"
        />

        <div v-if="!(message.facets as any)[activeTab]" class="pa-2">Nothing to preview</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { messageColor } from '#views/messages/utilities'

  import { AppStore } from '#stores'

  import { Insight, Message } from '#types'

  @Component({})
  class MessageItem extends Vue {
    @Prop() public type!: string
    @Prop() public insight!: Insight
    @Prop() public message!: Message
    @Prop() public snapshotURL?: string
    @Prop() public previousOrder?: number

    public activeTab = 'view'

    public messageColor = messageColor

    private appStore = new AppStore()

    public get inApprovals() {
      return this.appStore.inApprovals
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get inTipthemes() {
      return this.appStore.inTipthemes
    }

    public get inSlideshows() {
      return this.appStore.inSlideshows
    }
    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get canUserEditState() {
      return this.insight?.state !== 'approved' && this.appStore.canUserEdit
    }

    @Emit('duplicateMessage')
    public duplicateMessage(message: any) {
      return message
    }

    public mounted() {
      if (this.message?.facets?.view) {
        this.activeTab = 'view'
      } else if (this.message?.facets?.home) {
        this.activeTab = 'home'
      } else if (this.message?.media) {
        this.activeTab = 'media'
      } else if (this.message?.links) {
        this.activeTab = 'links'
      } else if (this.message?.facets?.notification) {
        this.activeTab = 'notification'
      } else {
        this.activeTab = 'view'
      }
    }

    public editMessage() {
      if (this.isInsightsEditor || this.canUserEditState) {
        if (this.inWorkspace || this.inApprovals) {
          this.$router.push(`/workspace/${this.appStore.user?.uid}/${this.insight!.id}/messages/${this.message.id}`)
        } else if (this.inTipthemes) {
          this.$router.push(`/tipthemes/${this.insight!.id}/messages/${this.message.id}`)
        } else if (this.inSlideshows) {
          this.$router.push(`/slideshows/${this.insight!.id}/messages/${this.message.id}`)
        } else {
          this.$router.push(`/messaging/${this.insight!.id}/messages/${this.message.id}`)
        }
      }
    }
  }

  export default toNative(MessageItem)
</script>

<style lang="scss" scoped>
  * {
    color: white;
  }
  .message-container:hover {
    cursor: pointer;
  }
  .snapshot {
    max-width: 100%;
  }
  .placeholder-background {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%),
      linear-gradient(135deg, #000 0%, #333 20%);
    background-size: cover;
  }
</style>
