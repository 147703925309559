import { AppStore } from './app'

import { Pinia, Store } from 'pinia-class-component'

import {
  FieldValue,
  Unsubscribe,
  arrayRemove,
  arrayUnion,
  doc,
  getFirestore,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import { EditMethods, UserPrefs } from '#types/prefs'

let unsubscribeUserPrefs: Unsubscribe | undefined = undefined

@Store
export class PrefsStore extends Pinia {
  public userPrefs: UserPrefs | undefined = undefined

  public async modifyUserPrefs(method: EditMethods, key: keyof UserPrefs, value: string) {
    const appStore = new AppStore()
    let fieldValue: string | FieldValue = value

    if (method === 'union') {
      fieldValue = arrayUnion(value)
    } else if (method === 'remove') {
      fieldValue = arrayRemove(value)
    }

    await setDoc(
      doc(getFirestore(), `accounts-profiles/${appStore.user?.uid}`),
      {
        [key]: fieldValue,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    )
  }

  public async subscribeToUserPrefs() {
    const appStore = new AppStore()

    unsubscribeUserPrefs = onSnapshot(doc(getFirestore(), `accounts-profiles/${appStore.user?.uid}`), (doc) => {
      this.userPrefs = doc.data() || undefined
    })
  }

  public unsubscribeFromUserPrefs() {
    this.userPrefs = {}

    if (unsubscribeUserPrefs) {
      unsubscribeUserPrefs()

      unsubscribeUserPrefs = undefined
    }
  }
}
