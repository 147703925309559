import dayjs from 'dayjs'

import { sortBy, upperFirst } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import {
  Unsubscribe,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { createDefaultInsight } from '#views/insights/utilities'

import { AppStore, ProjectsStore } from '#stores'

import { AmplitudeStats, Insight, Message } from '#types'

import { createZipAndDownload, prefixedId } from '#utilities'

let unsubscribeTags: Unsubscribe | undefined = undefined
let unsubscribeInsights: Unsubscribe | undefined = undefined

@Store
export class InsightsStore extends Pinia {
  public loading = true

  public stats: AmplitudeStats = {
    series: [],
    seriesLabels: [],
    seriesCollapsed: [],
    xValues: [],
  }

  public featureTags: any[] = []

  public allInsights: Insight[] = []
  public searchResults: Insight[] = []

  public searchQueryParams: string = ''

  public insightsStatistics: any[] = []

  public activeInsight: string | null = null

  public get insights() {
    const project = new ProjectsStore().project

    return this.allInsights.filter((r) => !project || project.id === r.project)
  }

  public async fetchStats(config?: any) {
    const today = new Date()

    const functions = getFunctions(getApp(), 'europe-west1')

    const dateTo = config?.dateTo || dayjs(today).format('YYYY-MM-DD')
    const dateFrom = config?.dataFrom || dayjs(today).subtract(7, 'day').format('YYYY-MM-DD')

    const env = process.env.VITE_CLOUD_ENV !== 'prod' ? 'dev' : process.env.VITE_CLOUD_ENV

    const response: any = await httpsCallable(
      functions,
      'fetchInsightStatsViaOutoApi',
    )({ insightId: config?.insightId, dateFrom, dateTo, env })

    if (response?.data?.insights) {
      this.insightsStatistics = response?.data?.insights || []
    }

    return response?.data?.results || []
  }

  public async updateInsight(insight: any) {
    insight.updatedAt = serverTimestamp()

    return await updateDoc(doc(getFirestore(), `/insights/${insight.id}`), insight)
  }

  public async exportInsights(data: any) {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'exportInsightsFromFirestore')(data)

    return response.data
  }

  public async downloadInsights(data: any) {
    const path = data.insightCollection || 'insights'

    const exportResult = await this.exportInsights(data)

    const latestRelease = await getDoc(doc(getFirestore(), `/rollouts/${path}`))

    await createZipAndDownload(
      upperFirst(path),
      {
        version: latestRelease.data()?.releasedVersion + '-export',
        insights: exportResult.insights.map((c: any) => c.content),
      },
      exportResult.translations,
    )

    return exportResult
  }

  public async updateProjects(data: any) {
    const appStore = new AppStore()

    const author = appStore.author

    author.comment = `Added to project ${data.project.name}`

    for (const insight of data.insights) {
      await updateDoc(doc(getFirestore(), `/insights/${insight.id}`), {
        author,
        project: data.project.id,
        updatedAt: serverTimestamp(),
      })
    }
  }

  public async updateCondition(insight: any) {
    const appStore = new AppStore()

    const author = { ...appStore.author }

    author.comment = 'Condition rules updated'

    const rules = await getDocs(
      query(collection(getFirestore(), `/rollouts/rules/content`), where('id', 'in', insight.rules)),
    )

    const condition = rules.docs
      .map((r) => r.data().condition)
      .concat(insight.rule)
      .filter(Boolean)
      .join(' && ')

    return await updateDoc(doc(getFirestore(), `/insights/${insight.id}`), {
      author,
      condition,
      rule: insight.rule,
      rules: insight.rules,
    })
  }

  public async updateSpotlight(insight: any) {
    const appStore = new AppStore()

    const author = appStore.author

    author.comment = 'Condition rules updated'

    return await updateDoc(doc(getFirestore(), `/insights/${insight.id}`), { spotlight: insight.spotlight, author })
  }

  public async updateConditions(data: any) {
    const appStore = new AppStore()

    const author = appStore.author

    author.comment = `Updated condition for ${data.rule.title} formula`
    author.displayName = data.displayName ?? null

    for (const insight of data.insights) {
      await updateDoc(doc(getFirestore(), `/insights/${insight.id}`), {
        author,
        condition: insight.condition,
        updatedAt: serverTimestamp(),
      })
    }
  }

  public async getMessages(insightType: string, insightId: string) {
    const messagesRef = await getDocs(collection(getFirestore(), `/${insightType}/${insightId}/messages`))

    return messagesRef.docs.map((doc) => doc.data() as Message) || []
  }

  public async getUserInsights() {
    const appStore = new AppStore()

    return await getDocs(query(collection(getFirestore(), `users/${appStore.user?.uid}/insights`)))
  }

  public async getInsight(insightId: string) {
    return await getDoc(doc(getFirestore(), `insights/${insightId}`))
  }

  /**
   * This method adds message to the provided insight category.
   * If the insight is provided, it will also add a new insight if one is provided.
   *
   * @param category Insight category
   * @param categoryId Insight category id
   * @param messages List of messages to set/update
   * @param insight (OPTIONAL) New insight to be added
   * @returns Promise
   */
  public async batchSetMessages(
    category: 'users' | 'slideshows' | 'insights',
    categoryId: string,
    messages: Message[],
    insight?: Insight,
  ) {
    const firestore = getFirestore()

    const batch = writeBatch(firestore)

    let categoryPath = `${category}/${categoryId}`
    if (insight && category === 'users') {
      categoryPath += `/insights/${insight.id}`
    }

    if (insight) {
      batch.set(doc(firestore, categoryPath), insight)
    }

    for (const message of messages) {
      batch.set(
        doc(firestore, `${categoryPath}/messages/${message.id}`),
        {
          ...message,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
        },
        { merge: true },
      )
    }

    return await batch.commit()
  }

  public async getDaphneThread(insightId: string) {
    return await getDoc(doc(getFirestore(), `insights/${insightId}/threads/daphnethread`))
  }

  public async getUserMessages(userId: string, insightId: string) {
    const messagesRef = await getDocs(
      query(collection(getFirestore(), `users/${userId}/insights/${insightId}/messages`)),
    )

    return messagesRef.docs.map((doc) => doc.data() as Message) || []
  }

  public async getReviews(userId: string, insightId: string) {
    const reviewsRef = await getDoc(doc(getFirestore(), `users/${userId}/reviews/${insightId}`))

    return reviewsRef.data() || {}
  }

  public async updateUserInsight(userId: string, insight: Partial<Insight> & { id: string }) {
    return await setDoc(
      doc(getFirestore(), `users/${userId}/insights/${insight.id}`),
      {
        ...insight,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    )
  }

  public async updateReview(userId: string, insightId: string, review: any) {
    return await updateDoc(doc(getFirestore(), `users/${userId}/reviews/${insightId}`), {
      [prefixedId('review')]: {
        ...review,
        date: serverTimestamp(),
      },
    })
  }

  /**
   * Update all messages of a given path with the provided message data.
   *
   * @path Path to the collection
   * @message Message data to update all messages with
   */
  public async updateMessagesByPath(firestorePath: string, message?: Partial<Message>) {
    return await getDocs(query(collection(getFirestore(), `${firestorePath}`))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => updateDoc(doc.ref, { ...(message ?? {}), updatedAt: serverTimestamp() }))
    })
  }

  public async updateInsightByPath(firestorePath: string, insight: Partial<Insight>) {
    const publishedAt = !insight?.publishedAt && insight?.state === 'experimental' ? serverTimestamp() : null

    return await updateDoc(doc(getFirestore(), firestorePath), {
      ...insight,
      ...(publishedAt ? { publishedAt } : {}),
      updatedAt: serverTimestamp(),
    })
  }

  public async subscribeToInsights() {
    if (!unsubscribeTags) {
      unsubscribeTags = onSnapshot(query(collection(getFirestore(), '/tags')), (snap) => {
        this.featureTags = snap.docs.map((doc) => ({
          value: doc.id,
          text: doc.data().name,
        }))
      })
    }

    if (!unsubscribeInsights) {
      this.loading = true

      unsubscribeInsights = onSnapshot(query(collection(getFirestore(), '/insights')), (snap) => {
        const insights = snap.docs
          .map((doc) => ({
            ...createDefaultInsight(),
            id: doc.id,
            ...doc.data(),
          }))
          .filter((i) => !this.insights.length || !!i.updatedAt)

        this.allInsights = sortBy(insights, [(o) => o.updatedAt || '']).reverse()

        this.loading = false
      })

      this.fetchStats()
    }
  }

  public async unsubscribeFromInsights() {
    if (unsubscribeTags) {
      unsubscribeTags()

      unsubscribeTags = undefined
    }

    if (unsubscribeInsights) {
      unsubscribeInsights()

      unsubscribeInsights = undefined
    }
  }
}
