<template>
  <v-timeline-item :dot-color="isInsightOwner(note.author.uid) ? 'blue' : 'grey'" icon="mdi-account" width="100%">
    <v-card elevation="2">
      <v-card-title class="flex flex-column justify-space-between">
        <div class="text-body-1 font-weight-bold ml-n2">{{ note.author.email }} wrote:&nbsp;</div>
        <div v-if="note.date !== null" class="text-caption text-grey ml-n2">{{ note.date }}</div>
      </v-card-title>

      <v-card-text color="text-black">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="note.message" />
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  @Component({})
  class Note extends Vue {
    @Prop() public note!: any

    private readonly appStore = new AppStore()

    public isInsightOwner(uid: string) {
      return this.appStore.user?.uid == uid
    }
  }

  export default toNative(Note)
</script>

<style lang="scss">
  .v-card__text {
    padding-bottom: 1px;
  }
</style>
