<template>
  <v-list rounded="0" elevation="0" class="pb-0">
    <v-list-subheader class="mt-n1" style="margin-left: -2px">
      <v-chip rounded="1" size="small" :text="projectKey" />
    </v-list-subheader>

    <v-divider class="mt-n5 mb-5 ml-14 mr-4" />

    <v-hover v-for="project in projects" :key="project.id">
      <template #default="{ isHovering, props }">
        <v-list-item
          v-bind="props"
          :title="project.name"
          :subtitle="project.team || ''"
          :class="{ 'text-primary': activeProject?.id === project.id }"
          @click="selectProject(project)"
        >
          <template v-if="canSeeStates" #prepend>
            <v-icon
              class="my-n2"
              :color="project.state && getStateColor(project.state)"
              :icon="
                (projectHasPendingChanges(project) || getStateColor(project.state)) &&
                `mdi-${projectHasPendingChanges(project) ? 'package-variant' : 'circle-medium'}`
              "
            />
          </template>

          <template v-if="isHovering || activeProject?.id === project.id" #append>
            <v-tooltip location="top center">
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  v-if="isPinned(project.id)"
                  v-bind="tooltipProps"
                  class="my-n2 mr-n1"
                  icon="mdi-pin-off"
                  color="primary"
                  @click.stop="unpinProject(project.id)"
                />

                <v-btn
                  v-else
                  v-bind="tooltipProps"
                  class="my-n2 mr-n1"
                  icon="mdi-pin"
                  color="primary"
                  @click.stop="pinProject(project.id)"
                />
              </template>

              {{ isPinned(project.id) ? 'Unpin' : 'Pin' }} project
            </v-tooltip>

            <v-tooltip location="top center">
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  v-bind="tooltipProps"
                  class="my-n2 mr-n3"
                  color="primary"
                  :icon="`mdi-${hasEditRights(project, userEmail, isWaltariAdmin) ? 'pencil' : 'eye'}`"
                  @click.stop="editProject(project)"
                />
              </template>

              {{ hasEditRights(project, userEmail, isWaltariAdmin) ? 'Edit' : 'View' }} project
            </v-tooltip>
          </template>
        </v-list-item>
      </template>
    </v-hover>
  </v-list>
</template>

<script lang="ts">
  import { projectStates } from './constants'
  import { hasEditRights, projectHasPendingChanges } from './utilities'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, PrefsStore } from '#stores'

  import { ProjectStateType, WaltariProject } from '#types'

  @Component({})
  class ProjectsMenu extends Vue {
    @Prop() public activeProject: WaltariProject | undefined

    @Prop({ required: true }) public projectKey!: string
    @Prop({ required: true }) public projects!: WaltariProject[]

    public readonly hasEditRights = hasEditRights
    public readonly projectHasPendingChanges = projectHasPendingChanges

    private readonly appStore = new AppStore()
    private readonly prefStore = new PrefsStore()

    @Emit('pinProject')
    public pinProject(projectId: string) {
      return projectId
    }

    @Emit('unpinProject')
    public unpinProject(projectId: string) {
      return projectId
    }

    @Emit('editProject')
    public editProject(project: WaltariProject) {
      return project
    }

    @Emit('selectProject')
    public selectProject(project: WaltariProject) {
      return project
    }

    public get userEmail() {
      return this.appStore.user?.email ?? ''
    }

    public get canSeeStates() {
      return this.appStore.isOTAContentAdmin || this.appStore.isWaltariAdmin
    }

    public get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    public get pinnedProjects() {
      return this.prefStore.userPrefs?.pinnedProjects
    }

    public getStateColor(state?: ProjectStateType) {
      return (projectStates.find((i) => i.value === state?.toLowerCase()) || {}).color
    }

    public isPinned(projectId: string) {
      return this.pinnedProjects?.includes(projectId)
    }
  }

  export default toNative(ProjectsMenu)
</script>
