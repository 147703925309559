<template>
  <div v-if="data.contents">
    <v-text-field v-model="data.counter" label="Days counter identifier" :rules="[validateId]" />

    <div v-for="(item, index) in data.contents" :key="index">
      <div class="d-flex flex-row">
        <span class="overline">Content for day {{ getDays(index) }}</span>
        <v-spacer />

        <v-btn icon="mdi-delete" @click="removeContent(index)" />
      </div>

      <div class="d-flex flex-row">
        <v-text-field v-model.number="item.days" label="Days to show" type="number" class="mr-4" />

        <v-text-field
          label="Content"
          readonly
          append-icon="mdi-view-grid-outline"
          class="mr-4"
          :model-value="(contentsData[index] && contentsData[index].title && contentsData[index].title.text) || ''"
          @click="galleryOpen = true"
        />

        <v-text-field
          label="Audio file"
          readonly
          class="mr-2"
          :disabled="!mediaFiles[index]"
          :append-icon="item.content ? (playing[index] ? 'mdi-pause' : 'mdi-play') : 'mdi-volume-high'"
          :model-value="(contentsData[index] && contentsData[index].media) || ''"
          @click="toggleAudioPlaying(index)"
          @click:append="toggleAudioPlaying(index)"
        />
      </div>

      <Translatable :data="item.text">
        <v-textarea v-model="item.text.text" spellcheck="true" lang="en" label="Text" rows="1" auto-grow />
      </Translatable>

      <div v-if="item.content && mediaFiles[index]" class="text-right">
        <audio ref="audioPlayerRef">
          <source :src="mediaFiles[index].url" :type="mediaFiles[index].content_type" />
        </audio>
      </div>

      <v-dialog v-if="galleryOpen" v-model="galleryOpen" width="800" scrollable>
        <MediaGallery
          title="Select audio file"
          :types="mediaTypes"
          :open="galleryOpen"
          @cancel="galleryOpen = false"
          @select="updateContent(index, $event)"
        />
      </v-dialog>
    </div>

    <div class="d-flex flex-row justify-center">
      <v-btn
        size="small"
        color="primary"
        :text="`Add recommendation for day ${getDays(data.contents.length + 1)}`"
        @click="addContent()"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { doc, getDoc, getFirestore } from '@firebase/firestore'

  import { createDefaultTranslatable } from '#views/messages/utilities'

  import { MediaStore } from '#stores'

  import { UIComponentContentRecommendation } from '#types'

  import { isIdentifierValid } from '#utilities'

  @Component({})
  class ContentRecommendation extends Vue {
    @Prop() public data!: UIComponentContentRecommendation

    declare public $refs: {
      audioPlayerRef: HTMLAudioElement[]
    }

    public playing: boolean[] = []

    public galleryOpen = false

    public mediaFiles: any[] = []
    public mediaTypes = ['audios']

    public contentsData: any[] = []

    public mediaStore = new MediaStore()

    public mounted() {
      this.loadMediaFiles()
    }

    public getDays(index: number) {
      const days: any = this.data.contents.reduce(
        (d: any, c: any, idx: number) => {
          if (idx <= index) {
            d.from = d.to + 1
            d.to = d.from - 1 + +c.days
          }

          return d
        },
        { from: 0, to: 0 },
      )

      if (index > this.data.contents.length) {
        days.from = days.to + 1
        days.to = days.from
      }

      return days.from === days.to ? days.from : `${days.from}-${days.to}`
    }

    public addContent() {
      this.data.contents.push({
        days: 1,
        text: createDefaultTranslatable(this.data.id, 'audio recommendation text'),
        content: '',
      })
    }

    public removeContent(index: number) {
      this.mediaFiles.splice(index, 1)
      this.contentsData.splice(index, 1)

      this.data.contents.splice(index, 1)
    }

    public updateContent(index: number, content: any) {
      this.galleryOpen = false

      this.data.contents[index].content = content.id

      this.loadMediaFiles(index)
    }

    public toggleAudioPlaying(index: number) {
      this.$refs.audioPlayerRef![index].load()

      if (this.playing[index]) {
        this.playing[index] = false
        this.$refs.audioPlayerRef![index].pause()
      } else {
        this.playing[index] = true
        this.$refs.audioPlayerRef![index].play()
      }

      this.playing = this.playing.slice(0)
    }

    public validateId(value: string) {
      return (
        isIdentifierValid(value.replace(/_/g, '!').replace(/-/g, '_')) ||
        'Invalid characters or too short id, use only lowercase letters, number and dash'
      )
    }

    public async loadMediaFiles(index?: number) {
      for (const [idx, item] of this.data.contents.entries()) {
        if (item.content && (index === undefined || index === idx)) {
          const contentsSnapshot = await getDoc(doc(getFirestore(), `contents/${item.content}`))

          this.contentsData[idx] = contentsSnapshot.data() || {}

          const response = await this.mediaStore.getMediaInfo({
            slug: this.contentsData[idx].media,
            type: 'audio',
          })

          const variants = response?.data?.info?.variants || []

          this.mediaFiles[idx] = variants[0]?.media_file
        }
      }

      this.contentsData = this.contentsData.slice(0)
      this.mediaFiles = this.mediaFiles.slice(0)
    }
  }

  export default toNative(ContentRecommendation)
</script>
