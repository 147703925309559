<template>
  <v-dialog v-model="isOpen" width="70%" max-width="800px">
    <v-card v-if="isOpen">
      <v-card-title class="headline">Create a new {{ messageName }}</v-card-title>

      <v-card-text class="py-8 px-8 d-flex flex-wrap">
        <v-btn icon class="template-button" @click="createMessage()">
          <div>
            <v-icon dark>mdi-file-document-outline</v-icon>
            <div>Blank</div>
          </div>
        </v-btn>
        <v-btn v-for="t in templates" :key="t.id" icon class="template-button" @click="createMessage(t)">
          <div>
            <v-icon dark>{{ t.icon }}</v-icon>
            <div>{{ t.name }}</div>
          </div>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { createMessage } from '#views/messages/utilities'

  import { AppStore } from '#stores'

  import { Insight, Message, Template } from '#types'

  @Component({})
  class CreateMessageDialog extends Vue {
    @Prop() public insight!: Insight
    @Prop() public messages!: Message[]

    @Prop() public insightPath!: string

    public isOpen = false

    public templates: any[] = []

    private appStore = new AppStore()

    private unsubscribe: Unsubscribe | undefined = undefined

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get messageName() {
      return this.insightPath.includes('slideshow') ? 'slide' : 'message'
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    private get insightsPath() {
      return this.insightPath.split('/')[1]
    }

    public open() {
      this.isOpen = true

      this.getTemplates()
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }

    public async createMessage(template?: Template) {
      const data = await createMessage({
        template,
        messages: this.messages,
        insightsPath: this.insightsPath,
        insight: this.insight,
        insightPath: this.insightPath,
      })

      this.editMessage(data)
    }

    private editMessage(message: Message) {
      if (this.inWorkspace) {
        this.$router.push(`/workspace/${this.appStore.user?.uid}/${this.insight!.id}/messages/${message.id}`)
      } else {
        this.$router.push(`/${this.rootPath}/${this.insight!.id}/messages/${message.id}`)
      }
    }

    private async getTemplates() {
      const currentPage = this.inWorkspace ? 'workspace' : this.inMessaging ? 'insights' : 'slideshows'

      this.unsubscribe = onSnapshot(query(collection(getFirestore(), `templates`)), (snap) => {
        this.templates = snap.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((data: any) => currentPage && data.allowedPages?.includes(currentPage))
      })
    }
  }

  export default toNative(CreateMessageDialog)
</script>

<style lang="scss" scoped>
  :deep(.v-btn) {
    &.template-button {
      height: auto;
      width: auto;
      margin: 4px;

      .v-ripple__container {
        display: none !important;
      }

      .v-btn__content {
        & > div {
          display: flex;
          flex-direction: column;
          border: 1px solid #dddddd;
          background: #fff;
          width: 100px;
          height: 100px;
          align-items: center;
          padding: 16px 0 10px 0;
          font-size: 16px;
          justify-content: start;
          border-radius: 3px;
          color: #666666;

          &.active {
            border-color: rgb(var(--v-theme-primary));
          }

          & > div {
            white-space: normal;
            font-size: 12px;
            letter-spacing: 0.5px;
            text-transform: none;
            font-weight: normal;
            padding: 8px 8px 10px;
          }
        }
      }
    }
  }
</style>
