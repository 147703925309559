<template>
  <v-menu offset="16px" location="bottom start" min-width="450" max-width="450" :disabled="isLoading" @click.stop>
    <template #activator="{ props }">
      <div v-bind="props" class="d-flex flex-row flex-shrink-1 align-center" style="cursor: pointer; min-width: 450px">
        <slot />

        <span class="text-truncate font-weight-light">
          {{ activeProject?.name || 'No project selected' }}
        </span>

        <v-btn icon="mdi-menu-down" variant="tonal" size="large" density="compact" class="mx-3 mt-n1" />
      </div>
    </template>

    <v-list>
      <v-text-field
        v-model="filter"
        autofocus
        clearable
        rounded="0"
        density="comfortable"
        variant="solo-filled"
        placeholder="Filter projects"
        append-inner-icon="mdi-magnify"
        @click.stop
      />

      <v-sheet v-if="!filter" rounded="0">
        <v-divider />

        <v-tabs v-model="activeTab" grow bg-color="surface" @click.stop>
          <v-tab
            v-for="(_, viewTitle) in projectViews"
            :key="viewTitle"
            :text="viewTitle"
            :value="viewTitle"
            @click.stop
          />
        </v-tabs>

        <v-divider />
      </v-sheet>

      <v-sheet height="350" rounded="0" style="overflow-y: auto">
        <template v-if="activeTab && allProjects.length">
          <v-alert
            v-if="!userPinnedProjects?.length && activeTab === ProjectTabs.PINNED"
            type="info"
            class="ma-4"
            icon="mdi-pin"
            text="Pinned projects will appear here"
          />

          <template v-for="projectKey in Object.keys(viewProjects)" :key="projectKey">
            <ProjectsList
              v-if="viewProjects[projectKey].length"
              :project-key="projectKey"
              :active-project="activeProject"
              :projects="viewProjects[projectKey]"
              @pin-project="pinProject($event)"
              @edit-project="editProject($event)"
              @unpin-project="unpinProject($event)"
              @select-project="selectProject($event)"
            />
          </template>
        </template>
      </v-sheet>

      <v-divider class="pb-2" />

      <v-list-item
        v-if="activeProject?.name"
        base-color="primary"
        prepend-icon="mdi-close"
        title="Clear selected project"
        @click="clearProject()"
      />

      <v-list-item prepend-icon="mdi-plus" base-color="primary" title="Create new project" @click="newProject()" />
    </v-list>
  </v-menu>

  <ProjectEditor
    v-if="!!action"
    :open="!!action"
    :action="action"
    :project="selectedProject"
    @close="action = ''"
    @new-project="newProject('Duplicate')"
  />
</template>

<script lang="ts">
  import { categorizeProjects, hasEditRights, isOwnProject, projectHasPendingChanges } from './utilities'

  import { v4 as uuid } from 'uuid'

  import { cloneDeep, some } from 'lodash-es'

  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppStore, PrefsStore, ProjectsStore } from '#stores'

  import { ProjectTabs, WaltariProject } from '#types'

  @Component({})
  class ProjectsMenu extends Vue {
    public action = ''
    public filter = ''

    public ProjectTabs = ProjectTabs
    public selectedProject: WaltariProject | null = null
    public activeTab: ProjectTabs | undefined = undefined
    public previousTab: ProjectTabs | undefined = undefined

    public projectViews: {
      [key in ProjectTabs]: { projects: { [key: string]: WaltariProject[] }; title: ProjectTabs }
    } = {
      [ProjectTabs.OWN]: {
        projects: {},
        title: ProjectTabs.OWN,
      },
      [ProjectTabs.PINNED]: {
        projects: {},
        title: ProjectTabs.PINNED,
      },
      [ProjectTabs.UNRELEASED]: {
        projects: {},
        title: ProjectTabs.UNRELEASED,
      },
      [ProjectTabs.ALL]: {
        projects: {},
        title: ProjectTabs.ALL,
      },
    }

    private readonly appStore = new AppStore()
    private readonly prefStore = new PrefsStore()
    private readonly projectsStore = new ProjectsStore()

    public get isLoading() {
      return this.projectsStore.loading
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get userPinnedProjects() {
      return this.prefStore?.userPrefs?.pinnedProjects
    }

    public get allProjects() {
      return this.projectsStore.projects
    }

    public get viewProjects() {
      if (!this.activeTab) {
        return {}
      }

      return this.projectViews[this.activeTab].projects
    }

    private get userEmail() {
      return this.appStore.user?.email ?? ''
    }

    private get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    private get hasOwnProjects() {
      return some(this.projectViews[ProjectTabs.OWN].projects, (projects) => projects.length > 0)
    }

    @Watch('filter')
    protected filterChanged() {
      if (!this.filter) {
        this.activeTab = this.previousTab
        this.previousTab = undefined

        this.updateProjectViews(this.allProjects)

        return
      }

      if (!this.previousTab) {
        this.previousTab = this.activeTab
        this.activeTab = ProjectTabs.ALL
      }

      const filteredProjects = this.allProjects.filter((project) =>
        project.name.toLowerCase().includes(this.filter.toLowerCase()),
      )

      this.updateProjectViews(filteredProjects)
    }

    @Watch('allProjects', { immediate: true })
    protected allProjectsChanged() {
      if (!this.allProjects) {
        return
      }

      this.updateProjectViews(this.allProjects)

      if (this.activeTab === undefined) {
        this.activeTab = this.hasOwnProjects ? ProjectTabs.OWN : ProjectTabs.ALL
      }
    }

    @Watch('userPinnedProjects', { immediate: true })
    protected userPinnedProjectsChanged() {
      this.updateProjectViews(this.allProjects)
    }

    public async pinProject(projectId: string) {
      await this.prefStore.modifyUserPrefs('union', 'pinnedProjects', projectId)
    }

    public async unpinProject(projectId: string) {
      await this.prefStore.modifyUserPrefs('remove', 'pinnedProjects', projectId)
    }

    /**
     * Update projectViews object with sorted projects lists
     */
    public updateProjectViews(projects: WaltariProject[]) {
      const { alphabetical, pinned } = categorizeProjects(projects, this.userPinnedProjects)

      const alphabeticalKeys = Object.keys(alphabetical)

      if (this.filter) {
        this.projectViews[ProjectTabs.ALL].projects = {}
      }

      alphabeticalKeys.sort().forEach((key: string) => {
        const sortedProjects = alphabetical[key].sort()
        const ownProjects = sortedProjects.filter((project) => isOwnProject(project, this.userEmail))
        const unreleasedProjects = sortedProjects.filter((project) => projectHasPendingChanges(project))

        this.projectViews[ProjectTabs.OWN].projects[key] = ownProjects
        this.projectViews[ProjectTabs.ALL].projects[key] = sortedProjects
        this.projectViews[ProjectTabs.UNRELEASED].projects[key] = unreleasedProjects
        this.projectViews[ProjectTabs.PINNED].projects[key] = pinned[key]?.sort() || []
      })
    }

    public newProject(action?: string) {
      this.action = action || 'Create'

      this.selectedProject = {
        id: uuid(),
        name: '',
        team: '',
        admins: [],
        owner: this.userEmail,
        sections: {
          content: false,
          features: false,
        },
        state: 'active',
      }
    }

    public clearProject() {
      this.projectsStore.setProject(null)
    }

    public editProject(project: WaltariProject) {
      this.action = hasEditRights(project, this.userEmail, this.isWaltariAdmin) ? 'Edit' : 'View'

      this.selectedProject = cloneDeep(project)
    }

    public selectProject(project: WaltariProject) {
      if (this.activeProject?.id === project.id) {
        this.editProject(project)
      } else {
        this.projectsStore.setProject(project)
      }
    }
  }

  export default toNative(ProjectsMenu)
</script>
