<template>
  <div class="d-flex flex-column fill-height flex-nowrap" :class="hasChanges ? 'dirty' : ''">
    <template v-if="isLoading">
      <v-progress-circular class="ma-auto" size="96" indeterminate color="primary" />
    </template>
    <template v-else>
      <v-card class="d-flex flex-column flex-grow-1 fill-height align-stretch white mt-4">
        <Facets
          :key="pages.length"
          :insight="insight"
          :message="message"
          :messages="messages"
          :tab="activeTab"
          :message-path="messagePath"
          :allowed-components="template ? template.allowedComponents : []"
          :allowed-facets="template ? template.allowedFacets : []"
          :required-components="template ? template.requiredComponents : []"
          @change-active-tab="changeActiveTab($event)"
          @create-new-links-item="createNewLinksItem()"
          @open-media-item-dialog="openMediaItemDialog()"
        />
      </v-card>
    </template>
  </div>

  <SaveChanges
    v-if="message && originalMessage"
    :original="originalMessage"
    :current="message"
    :template="template"
    :loading="isLoading || isSaving"
    :author="author"
    :edited="edited"
    :data-source="message"
    :deletable="message && message.state === 'draft'"
    :error="!insight || !insight.project ? 'Project not set, changes can not be saved' : null"
    :action="message && message.state !== 'draft' ? 'duplicate' : null"
    @save="inWorkspace ? saveForWorkspace() : openCommitDialog()"
    @open-history-dialog="openHistoryDialog()"
    @confirm-delete="deleteMessage()"
    @execute="duplicateMessage()"
  />

  <MessagePreview ref="messagePreview" />

  <HistoryDialog ref="historyDialog" />
  <CommitDialog ref="commitDialog" />

  <ChangeStateDialog ref="changeStateDialog" />

  <CreateNewMediaItemDialog
    v-if="message"
    ref="createNewMediaItemDialog"
    :message-id="message.id"
    @add-media-item="addMediaItem($event)"
  />
</template>

<script lang="ts">
  import { cloneDeep, isEqual, sortBy } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    Unsubscribe,
    collection,
    deleteDoc,
    deleteField,
    doc,
    getDocs,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
  } from 'firebase/firestore'

  import { messageStates } from '#views/insights/constants'

  import {
    createDefaultMessage,
    createLinksObjectItem,
    duplicateExistingMessage,
    getOrigin,
  } from '#views/messages/utilities'

  import { AppStore } from '#stores'

  import { Author, Dialog, Insight, MediaItemType, Message, Preview, Template } from '#types'

  @Component({ inheritAttrs: false })
  class MessageEditor extends Vue {
    @Prop() public insightId!: string

    @Prop() public insightPath!: string
    @Prop() public messagePath!: string

    @Emit('changed')
    public emitChanged() {
      return this.hasChanges
    }

    declare public $refs: {
      commitDialog: Dialog
      historyDialog: Dialog
      messagePreview: Preview
      changeStateDialog: Dialog
      createNewMediaItemDialog: Dialog
    }

    public pages: any = []

    public isSaving = false
    public isLoading = true

    public messages: Message[] = []

    public messagesPath: string = ''

    public activeTab: string = 'type'

    public author: Author | null = null
    public insight: Insight | null = null
    public messageIds: string[] | null = null

    public edited: string = '(No edit history)'

    public message: Message | null = null
    public template: Template | null = null
    public originalMessage: Message | null = null

    public messageStates = messageStates

    private appStore = new AppStore()

    private unsubscribeInsight: Unsubscribe | undefined = undefined
    private unsubscribeMessage: Unsubscribe | undefined = undefined
    private unsubscribeMessages: Unsubscribe | undefined = undefined

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get messageId() {
      return this.messagePath.split('/').pop()!
    }

    public get hasChanges() {
      return !isEqual(this.originalMessage, this.message)
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    private get messageCollectionPath(): string {
      const components = this.messagePath.split('/')
      components.pop()
      return components.join('/')
    }

    @Watch('hasChanges')
    protected onHasChangesChanged() {
      this.emitChanged()
    }

    @Watch('insightPath')
    protected onInsightPathChanged() {
      this.getInsight()
      this.getMessages()
    }

    @Watch('messagePath')
    protected onMessagePathChanged() {
      this.getAuthor()
      this.getMessage()
      this.getMessages()
    }

    public mounted() {
      this.getAuthor()
      this.getInsight()
      this.getMessage()
      this.getMessages()
    }

    public beforeUnmount() {
      if (this.unsubscribeInsight) {
        this.unsubscribeInsight()
        this.unsubscribeInsight = undefined
      }

      if (this.unsubscribeMessage) {
        this.unsubscribeMessage()
        this.unsubscribeMessage = undefined
      }

      if (this.unsubscribeMessages) {
        this.unsubscribeMessages()
        this.unsubscribeMessages = undefined
      }
    }

    public async duplicateMessage() {
      if (this.message) {
        const messageId = await duplicateExistingMessage(
          this.insightPath.split('/')[1],
          this.message,
          this.insightId,
          this.messages.length,
        )

        this.$router.push(`/${this.rootPath}/${this.insightId}/${messageId}`)
      }
    }

    public messageColorById(id: string) {
      const message = this.messages.find((m) => m.id === id)
      return messageStates.find((s) => s.value === (message && message.state))?.color || 'grey lighten-2'
    }

    public changeActiveTab(tab: string) {
      this.activeTab = tab
    }

    public async removeTemplate() {
      await updateDoc(doc(getFirestore(), this.messagePath), {
        template: deleteField(),
      })
    }

    public async createMessage() {
      this.isLoading = true

      const data = {
        ...createDefaultMessage(this.insightId, getOrigin(this.insightPath), this.messages.length),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }

      await setDoc(doc(getFirestore(), `${this.insightPath}/messages`, data.id), data)

      this.isLoading = false

      this.$router.replace(`/${this.rootPath}/${data.id}`)
    }

    public addMediaItem(data: MediaItemType) {
      if (this.message) {
        const currentMedia = this.message.media ?? []

        this.message = { ...this.message, media: [...currentMedia, data] }

        this.activeTab = 'media'
      }
    }

    public createNewLinksItem() {
      if (this.message) {
        const currentLinks = this.message.links ?? []

        const link = createLinksObjectItem(this.message.id)

        const data = {
          ...link,
          id: `${this.message.id}:link:${link?.id}`,
        }

        this.message = { ...this.message, links: [...currentLinks, data] }

        this.changeActiveTab('links')
      }
    }

    public openHistoryDialog() {
      const name = this.messagePath.split('/').pop()!

      this.$refs.historyDialog.open(this.messagePath, name)
    }

    public openMediaItemDialog() {
      this.$refs.createNewMediaItemDialog.open()
    }

    public async openCommitDialog() {
      this.isSaving = true

      this.message!.project = this.insight!.project
      this.originalMessage!.project = this.insight!.project

      const screenshot = await this.$refs.messagePreview.screenshot(this.message!, 'view')

      this.$refs.commitDialog.open(
        this.messageId,
        this.messagePath,
        this.message,
        this.originalMessage,
        null,
        screenshot,
      )

      this.isSaving = false
    }

    public async deleteMessage() {
      this.$confirm('Confirm message deleting?').then(async (confirmed) => {
        if (confirmed) {
          this.isLoading = true

          const orderNumber = this.message?.order

          await deleteDoc(doc(getFirestore(), this.messagePath))

          if (orderNumber) {
            for (const message of this.messages) {
              if (message.order > orderNumber) {
                await updateDoc(doc(getFirestore(), `${this.messageCollectionPath}/${message.id}`), {
                  order: message.order - 1,
                })
              }
            }
          }

          this.isLoading = false

          this.$router.push(`/${this.rootPath}/${this.insightId}`)
        }
      })
    }

    public async saveForWorkspace() {
      this.isLoading = true

      const data = { ...this.message, author: this.appStore.author, updatedAt: serverTimestamp() }

      await setDoc(doc(getFirestore(), this.messagePath), data)

      this.isLoading = false
    }

    private async getAuthor() {
      const dbCollection = await getDocs(query(collection(getFirestore(), `${this.messagePath}/history`)))
      const docs = sortBy(
        dbCollection.docs.map((d) => d.data()),
        'timestamp',
      ).reverse()

      if (docs[0]) {
        this.author = docs[0].after.author
        this.edited = docs[0].timestamp.toDate()
      }
    }

    private async getInsight() {
      this.insight = null

      if (this.unsubscribeInsight) {
        this.unsubscribeInsight()
      }

      this.unsubscribeInsight = await onSnapshot(doc(getFirestore(), this.insightPath), (snap) => {
        const data = snap.data()

        if (!data) {
          return
        }

        const insight = data as Insight
        this.insight = cloneDeep(insight)
      })
    }

    private async getMessage() {
      this.originalMessage = null
      this.message = null
      this.isLoading = true

      if (this.unsubscribeMessage) {
        this.unsubscribeMessage()
      }

      this.unsubscribeMessage = await onSnapshot(doc(getFirestore(), this.messagePath), async (snap) => {
        const data = snap.data()

        if (!data) {
          return
        }

        const message = data as Message

        this.originalMessage = message
        this.message = cloneDeep(message)

        this.isLoading = false

        await onSnapshot(doc(getFirestore(), `templates/${message!.template}`), (snap) => {
          this.template = snap.data() as Template
          if (message.template && !this.template) {
            this.removeTemplate()
          }
        })
      })
    }

    private async getMessages() {
      if (this.unsubscribeMessages) {
        this.unsubscribeMessages()
      }
      this.unsubscribeMessages = await onSnapshot(
        query(collection(getFirestore(), this.messageCollectionPath), orderBy('order')),
        (snap) => {
          this.messages = snap.docs.map((doc) => doc.data()) as Message[]
          this.messageIds = this.messages.map((doc) => doc.id)
        },
      )
    }
  }

  export default toNative(MessageEditor)
</script>

<style lang="scss" scoped>
  .sticky-header {
    position: sticky;
    width: 100%;
    top: 64px;
    max-height: 96px;
    z-index: 2;
    background: #f8f8fb;
  }

  .placeholder-background {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%),
      linear-gradient(135deg, #000 0%, #333 30%);
    background-size: cover;
    min-width: 350px;
    max-width: 350px;
  }
</style>
