import { concat } from 'lodash-es'

import { Project, project_StaticIdFromJSON, project_StaticIdToJSON } from '@jouzen/feature-mgmt-api/metadata'

import { AppStore, ProjectsStore } from '#stores'

import { CategorizedProjects, Criteria, Feature, Override, WaltariProject } from '#types'

// Feature flag project getter helpers

// TODO: Move any helper functions using store into the stores themselves, utilities should be pure functions
export function isProjectEditor(data: Feature | Criteria | Override) {
  const appStore = new AppStore()
  const projectsStore = new ProjectsStore()

  // TODO: Remove any hack once metadata fixed in backend

  const userEmail = appStore.user?.email
  const project = projectsStore.projects.find((p) => p.id === data?.metadata?.informative?.labels?.project)
  const isOwn = project && userEmail ? isOwnProject(project, userEmail, data?.metadata?.changeRecord?.createdBy) : false

  return appStore.isOnCallUser || appStore.isWaltariAdmin || (appStore.isAppFeatureAdmin && isOwn)
}

export function getProjectKey(data: Feature | Criteria) {
  return data.metadata!.project!.oneOf!.$case === 'external'
    ? data.metadata!.project!.oneOf!.external || 'unknown'
    : project_StaticIdToJSON(data.metadata!.project!.oneOf!.apiStatic).toLowerCase()
}

export function getProjectName(data: Feature | Criteria | Override) {
  const projectsStore = new ProjectsStore()

  // TODO: Remove any hack once metadata fixed in backend

  return projectsStore.projects.find((p) => p.id === data?.metadata?.informative?.labels?.project)?.name
}

export function getProjectTeam(data: Feature | Criteria | Override) {
  const projectsStore = new ProjectsStore()

  // TODO: Remove any hack once metadata fixed in backend

  return projectsStore.projects.find((p) => p.id === data?.metadata?.informative?.labels?.project)?.team
}

// Feature flag project creation helpers

export function createProjectData(project: string) {
  const projectsStore = new ProjectsStore()

  const projectKey = projectsStore.projects.find((p) => p.id === project)?.key ?? 'testing_waltari'

  const staticProject = project_StaticIdFromJSON(projectKey.toUpperCase()) || -1

  return staticProject === -1
    ? ({ oneOf: { $case: 'external', external: projectKey } } as Project)
    : ({ oneOf: { $case: 'apiStatic', apiStatic: staticProject } } as Project)
}

export const projectHasPendingChanges = (project: WaltariProject): boolean => {
  const createdOrReleasedAt = project?.releasedAt || project?.createdAt

  return createdOrReleasedAt && project.changesAt ? createdOrReleasedAt.toDate() < project.changesAt.toDate() : false
}

export const isOwnProject = (project: WaltariProject, userEmail: string, additionalFields?: string | string[]) => {
  return concat(project?.owner, project?.admins, project?.editors, additionalFields).includes(userEmail)
}

export const hasEditRights = (project: WaltariProject, userEmail: string, isWaltariAdmin: boolean) => {
  return project.admins?.includes(userEmail) || userEmail === project.owner || isWaltariAdmin
}

/**
 * Categorize projects into lists of pinned projects and alphabetically sorted projects
 */
export const categorizeProjects = (projects: WaltariProject[], pinnedProjects: string[] | undefined) => {
  return projects.reduce(
    (acc, project) => {
      const firstLetter = project.name[0].toUpperCase()
      const isPinned = pinnedProjects?.includes(project.id)

      if (!Object.hasOwn(acc.alphabetical, firstLetter)) {
        acc.alphabetical[firstLetter] = [project]
      } else {
        acc.alphabetical[firstLetter].push(project)
      }

      if (isPinned && !Object.hasOwn(acc.pinned, firstLetter)) {
        acc.pinned[firstLetter] = [project]
      } else if (isPinned) {
        acc.pinned[firstLetter].push(project)
      }

      return acc
    },
    { alphabetical: {}, pinned: {} } as CategorizedProjects,
  )
}
