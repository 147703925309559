<template>
  <div class="px-2 pt-2">
    <div class="body-2 pb-2">{{ text }}</div>

    <audio ref="audioPlayerRef" controls style="max-width: 100%">
      <source :src="src" :type="type" />
    </audio>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { doc, getDoc, getFirestore } from '@firebase/firestore'

  import { MediaStore } from '#stores'

  import { UIComponentContentRecommendation } from '#types'

  @Component({})
  class ContentRecommendationPreview extends Vue {
    @Prop() public component!: UIComponentContentRecommendation

    declare public $refs: {
      audioPlayerRef: HTMLAudioElement
    }

    public src: string = ''
    public type: string = ''
    public text: string = ''

    private mediaStore = new MediaStore()

    public mounted() {
      this.loadMediaFile()
    }

    public async loadMediaFile() {
      const content = this.component.contents[0]?.content

      if (content) {
        const contentsSnapshot = await getDoc(doc(getFirestore(), `contents/${content}`))

        const contents = contentsSnapshot.data()

        if (contents?.media) {
          const response = await this.mediaStore.getMediaInfo({
            slug: contentsSnapshot.data()!.media,
            type: 'audio',
          })

          const variants = response?.data?.info?.variants || []

          this.src = variants[0]?.media_file.url || ''
          this.type = variants[0]?.media_file.content_type || ''

          this.text = (this.component.contents[0]?.text.text || '').replace(/{title}/g, contents.title.text)

          window.setTimeout(() => {
            this.$refs.audioPlayerRef!.load()
          }, 0)
        }
      }
    }
  }

  export default toNative(ContentRecommendationPreview)
</script>
